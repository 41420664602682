/*----------------------
    Mobile Menu Styles  
-------------------------*/

.popup-mobilemenu-area.show {
    visibility: visible;
    opacity: 1;
    .inner {
        transform: none;
    }
    @media only screen and (min-width: 1200px) {
        display: none;
    }
}


.popup-mobilemenu-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.9);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 99999;

    .inner {
        background: var(--color-white);
        position: relative;
        width: 320px;
        max-width: 80%;
        height: 100%;
        text-align: left;
        -webkit-transition: all .3s;
        transition: all .3s;
        overflow-y: auto;
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        cursor: default;

        // Mobile Menu Top 
        .mobile-menu-top {
            padding: 20px;
            border-bottom: 1px solid var(--color-lightest);
            padding-bottom: 26px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo {
                a {
                    display: block;
                    img {
                        max-height: 37px;
                    }
                }
            }
            .mobile-close {
                cursor: pointer;
                i {
                    font-size: 22px;
                    color: #000000;
                    font-weight: 300;
                    transition: 0.3s;
                }
                &:hover {
                    i {
                        color: var(--color-primary);
                    }
                }
            }
        }

        .buy-now-btn {
            padding: 0 20px;
            margin-top: 8px;
            a {
                display: inline-block;
                color: #65676B;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;
                @extend %transition;
                &:hover {
                    color: var(--color-primary);
                }
                .badge {
                    background: #D93E40;
                    color: #F0F2F5;
                    font-size: 12px;
                    border-radius: 10px;
                    padding: 3px 9px;
                    margin-left: 17px;
                }
            }
            
        }

    }

    // Popup Mobile Menu 
    .mainmenu {
        @extend %liststyle;
        padding: 5px 20px;
        > li {
            margin: 0;
            padding: 15px 0;
            border-bottom: 1px solid var(--color-lightest);
            a {
                font-size: 16px;
                line-height: 20px;
                color: #65676B;
                font-weight: 500;
                &.open,
                &:hover {
                    color: var(--color-primary);
                }
            }
        }

        // Menu Item Has Children 
        .menu-item-has-children {
            > a {
                position: relative;
                display: block;
                &::after {
                    position: absolute;
                    content: "\f107";
                    font-family: var(--font-awesome);
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    @extend %transition;
                }
                &.open {
                    color: var(--color-primary);
                    &::after {
                        content: "\f106";
                    }
                }
            }
            .axil-submenu {
                @extend %liststyle;
                height: 0;
                visibility: hidden;
                opacity: 0;
                padding-left: 20px;
                padding-top: 10px;
                li {
                    margin-top: 0;
                    margin-bottom: 0;
                    a {
                        padding: 8px 0;
                        display: block;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                &.open {
                    visibility: visible;
                    opacity: 1;
                    height: auto;
                }
            }
        }

        .megamenu-wrapper {
            display: none;
        }
    }
}
