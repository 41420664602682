/* --------------------
Sidebar Styles  
----------------------*/
.axil-single-widget {
    // background: #FBFBFD;
    @extend %radius;
    // padding: 30px;
    // @media #{$sm-layout} {
    //     padding: 20px;
    // }
    &:last-child {
        margin-bottom: 0;
    }

    input {
        height: 40px;
        border-radius: 44px;
        font-size: var(--font-size-b3);
        line-height: var(--line-height-b3);
        color: var(--color-gray);
        padding: 0 30px;
    }

    .form-submit {
        button,
        a {
            &.axil-button {
                height: 40px;
                line-height: 34px;
            }
        }
    }

    .widget-title {
        border-left: 3px solid var(--foundation-primary-color-normal);
        padding-bottom: 4px;
        padding-top: 4px;
        margin-bottom: 20px;
        padding-left: 15px;
        padding-right: 10px;
    }

    // Widget Categories 
    &.widget_categories {
        background: transparent;
        padding: 0;
        ul {
            @extend %liststyle;
            display: flex;
            flex-wrap: wrap;
            margin: -5px;
            li {
                &.cat-item {
                    padding: 5px;
                    margin: 0;
                    flex-basis: 50%;
                    .inner {
                        background: #FBFBFD;
                        @extend %radius;
                        display: flex;
                        padding: 5px;
                        align-items: center;
                        .thumbnail {
                            width: 50px;
                            margin-right: 15px;
                            img {
                                width: 100%;
                                border-radius: 100%;
                            }
                            span {
                                display: block !important;
                            }
                        }
                        .content {
                            .title {
                                margin-bottom: 0;
                                color: var(--color-gray); 
                                @extend %transition;
                            }
                        }
                        &:hover {
                            .content {
                                .title {
                                    color: var(--color-primary); 
                                }
                            }
                        }
                    }
                }
            }

        }
    }


    // Widget Instagram 
    &.widget_instagram {
        .instagram-post-list-wrapper {
            @extend %liststyle;
            display: flex;
            margin: -4px;
            flex-wrap: wrap;
            .instagram-post-list {
                flex-basis: 33.33%;
                padding: 4px;
                margin: 0;
                @media #{$md-layout} {
                    flex-basis: 20%;
                }
               
                @media #{$sm-layout} {
                    flex-basis: 33.33%;
                }
                a {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    @extend %radius;
                    span {
                        display: block !important;
                    }
                    img {
                        @extend %radius;
                        width: 100%;
                        transition: 0.5s;
                    }
                }
                &:hover {
                    a {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

        }
    }

    // Widget Search 
    &.widget_search {
        .axil-search {
            &.form-group {
                display: block;

                input {
                    height: 40px;
                    border: 0 none;
                    max-width: 100%;
                    font-size: 14px;
                    line-height: 22px;
                    padding-left: 44px;
                }

                button {
                    &.search-button {
                        i {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    &.widget_postlist {

    }

    &.widget_ads {
        padding: 0;
        background-color: transparent;
        text-align: center;
        .thumbnail {
            a {
                display: block;
            }
        }
    }

    &.widget_newsletter {
        p {
            br {
                @media #{$lg-layout} {
                    display: none;
                }
            }
        }
    }

    &.widget-style-2 {
        background: #fff;
        &.widget_categories {
            background: transparent;
        }

        &.widget_post {
            .content-block {
                .post-content {
                    margin-top: 10px;
                }
            }  
        }
    }

    .tagcloud {
        display: flex;
        flex-wrap: wrap;
        margin: -5px;
        a {
            border: 1px solid var(--color-lighter);
            font-size: 14px;
            color: var(--color-gray);
            height: 40px;
            padding: 0 20px;
            margin: 5px;
            display: inline-block;
            line-height: 39px;
            border-radius: 500px;
            @extend %transition;
            &:hover {
                background: var(--color-primary);
                color: #ffffff;
                border-color: var(--color-primary);
            }
        }
    }
    select {
        transition: 0.3s;
        height: 40px;
        padding: 0 20px;
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        border: 1px solid var(--color-border);
        border-radius: 4px;
        background: url(/images/icons/arrow-icon.png) 95% center no-repeat transparent;
        padding-right: 32px;
        color: #65676B;
        font-size: 14px;
        line-height: 22px;
    }
}





