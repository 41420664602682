/*===========================
    Start Footer Area 
=============================*/
.axil-footer-style-1 {
  padding: 40px 0;

  .footer-top {
    .inner {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--color-lighter);
      margin-bottom: 20px;

      .follow-title {
        @media #{$small-mobile} {
          margin-bottom: 10px !important;
        }
      }
    }
  }
}

.copyright-area {
  .mainmenu {
    @extend %liststyle;
    display: flex;

    li {
      margin: 0 18px;

      a {
        color: var(--color-midgray);
        font-size: 16px;
        line-height: 22px;
        display: block;
        font-weight: 500;
        @extend %transition;

        &.hover-flip-item-wrapper {
          span {
            &::before {
              color: var(--color-midgray);
            }

            &::after {
              color: var(--color-primary);
            }
          }
        }
      }

    }
  }
}



/* Copyright Area  */
.copyright-area {
  .copyright-left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .logo {
      margin-right: 53px;
      min-height: 38px;
      min-width: 141px;

      a {
        line-height: 1;
        display: block;
      }

      img {
        max-height: 37px;
      }

      @media #{$sm-layout} {
        flex-basis: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .copyright-right {
    p {
      color: var(--color-midgray);
    }
  }

  ul {
    &.mainmenu {
      flex-wrap: wrap;
      margin-bottom: -10px;

      li {
        margin-bottom: 10px;

        @media #{$sm-layout} {
          margin: 0 5px;
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          height: auto;
          line-height: initial;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.footer-mainmenu {
  padding-bottom: 80px;
  padding-top: 50px;

  .footer-widget {
    .title {
      font-size: 18px;
      line-height: 22px;
      color: #050505;
    }

    ul {
      &.ft-menu-list {
        @extend %liststyle;

        li {
          margin-top: 5px;
          margin-bottom: 5px;

          a {
            color: #000000;
            font-size: 16px;
            line-height: 30px;
            transition: 0.3s;

            &:hover {
              color: var(--color-primary) !important;
            }
          }
        }
      }
    }
  }
}

.axil-footer-style-1 {
  &.footer-variation-2 {
    .footer-widget {
      margin-top: 40px;
    }

    .logo {
      img {
        max-height: 37px;

        &.white-logo {
          display: none !important;
        }
      }
    }

    .footer-top {
      padding-bottom: 30px;
      margin-bottom: 20px;
      padding-top: 30px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        background: var(--color-lighter);
        max-width: 1230px;
        height: 1px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 0;
      }

      &::after {
        position: absolute;
        content: "";
        background: var(--color-lighter);
        max-width: 1230px;
        height: 1px;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}


.axil-footer-style-1 {
  &.footer-variation-three {
    .logo {
      img {
        max-height: 37px;
      }
    }

    .footer-top {
      padding-bottom: 30px;
      margin-bottom: 20px;
      position: relative;

      &::after {
        position: absolute;
        content: "";
        background: #333333;
        max-width: 1230px;
        height: 1px;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    ul {
      &.social-icon {
        li {
          a {
            background: #333333;
            color: #ffffff;
            transition: 0.3s;

            &:hover {
              background: var(--color-primary);
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}


.axil-footer-style-1.footer-variation-three .logo img.dark-logo {
  display: none;
}

.copyright-area .copyright-left .logo img.dark-logo {
  display: block;
}

.copyright-area .copyright-left .logo img.light-logo {
  display: none !important;
}


//figma
.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
}

.frame .div {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .text-wrapper {
  position: relative;
  align-self: stretch;
  margin-bottom: 0;
  font-family: var(--title-s16-21-bold-font-family);
  font-weight: var(--title-s16-21-bold-font-weight);
  color: var(--foundationprimary-colorlight);
  font-size: var(--title-s16-21-bold-font-size);
  letter-spacing: var(--title-s16-21-bold-letter-spacing);
  line-height: var(--title-s16-21-bold-line-height);
  font-style: var(--title-s16-21-bold-font-style);
}

.frame .div-2 {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);

  a {
    transition: 0.5s;
    &:hover {
      color: var(--foundationprimary-colorlight-active) !important;
    }
  }
}

.frame .text-wrapper-3 {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: var(--foundationprimary-colorlight);
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
}

.frame .div-3 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  margin-top: 0 !important;

  i {
    font-size: 14px;
    color: var(--foundationprimary-colorlight-active);
  }
}

.frame .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 7px 12px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--foundation-primary-color-normal) !important;
  color: white;
  border-radius: 5px !important;
  all: unset;
  box-sizing: border-box;
}

.frame .inter {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-small-re-font-family);
  font-weight: var(--body-small-re-font-weight);
  color: white;
  font-size: var(--title-small-re-font-size);
  letter-spacing: var(--body-small-re-letter-spacing);
  line-height: var(--body-small-re-line-height);
  font-style: var(--body-small-re-font-style);
  margin: 7px 15px;
}

.frame .div-7 {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
  min-height: 21px;

  @media #{$md-layout} {
    margin-top: 8px !important;
  }

  @media #{$lg-layout} {
    margin-top: 8px !important;
  }
}

.mobile-frame .div-7 {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
  min-height: 21px;
}

.frame .div-8 {
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;

  i {
    font-size: 14px;
    color: var(--foundationprimary-colorlight-active);
  }
}

.frame .text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-s13-17-regular-font-family);
  font-weight: var(--body-s13-17-regular-font-weight);
  color: var(--foundationprimary-colorlight-active);
  font-size: var(--body-s13-17-regular-font-size);
  letter-spacing: var(--body-s13-17-regular-letter-spacing);
  line-height: var(--body-s13-17-regular-line-height);
  font-style: var(--body-s13-17-regular-font-style);
}

.frame .div-4 {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
  margin-top: 0 !important;

  i {
    font-size: 14px;
    color: var(--foundationprimary-colorlight-active);
  }
}

.frame .img-2 {
  position: relative;
  width: 16px;
  height: 16px;
}

.frame .p {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
}

.frame .div-5 {
  display: flex;
  flex-direction: column;
  width: 282px;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  padding: 10px;
  background-color: var(--foundation-black-colordark-active);
  border-radius: 4px;
  position: relative;
  flex: 0 0 auto;
}

.frame .div-6 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  align-self: stretch;
  width: 100%;
  border-radius: 4px;
  border: 0px none;
  position: relative;
  flex: 0 0 auto;
  border-color: var(--foundationprimary-colorlight-active);
  margin-top: 0 !important;
}

.frame .text-wrapper-4 {
  position: relative;
  flex: 1;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: var(--foundationprimary-colorlight);
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
  transition: 0.5s;

  &:hover {
    color: var(--foundationprimary-colorlight-active);
  }
}

.frame .div-9 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-m14-19-regular-font-size);
  text-align: center;
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);

  .image {
    position: relative;
    width: 246px;
    height: 67px;
  }
}

.frame {
  &.border-1 {
    display: flex;
    flex-direction: column;
    height: 138px;
    align-items: center;
    gap: 9px;
    padding: 12px;
    position: relative;
    border-radius: 4px;
    border: 1px solid;
    border-color: var(--foundationprimary-colorlight);
  }
}

.text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--title-small-re-font-family);
  font-weight: var(--title-small-re-font-weight);
  color: #ffffff;
  font-size: var(--title-small-re-font-size);
  letter-spacing: var(--title-small-re-letter-spacing);
  line-height: var(--title-small-re-line-height);
  font-style: var(--title-small-re-font-style);
  transition: 0.5s;

  &:hover {
    color: var(--foundationprimary-colorlight-active);
  }

  // margin-right: 24px
}

.logo-dark {
  position: relative;
  height: 60px;
  max-width: 243px;
}

.register-notification {
  display: flex;
  height: 36px;
  align-items: center;
  padding: 8px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;

  .text-wrapper {
    position: relative;
    flex: 1;
    margin-top: -0.5px;
    font-family: var(--body-m14-19-regular-font-family);
    font-weight: var(--body-m14-19-regular-font-weight);
    color: var(--foundation-black-colornormal);
    font-size: var(--body-m14-19-regular-font-size);
    letter-spacing: var(--body-m14-19-regular-letter-spacing);
    line-height: var(--body-m14-19-regular-line-height);
    font-style: var(--body-m14-19-regular-font-style);
  }

  i {
    position: relative;
    width: 20px;
    height: 20px;
    color: var(--foundationprimary-colornormal);
  }
}

.mobile-frame {
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;
  background-color: var(--foundation-black-colordarker);
  padding: 0 10px;

  i {
    font-size: 14px;
    color: var(--foundationprimary-colorlight-active);
  }
}

.mobile-frame .mobile-div {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  justify-content: space-evenly;
}

.mobile-frame .mobile-div-2 {
  display: flex;
  width: 188px;
  align-items: flex-end;
  gap: 12px;
  position: relative;
}

.mobile-frame .mobile-logo {
  position: relative;
  width: 88.67px;
  height: 40px;
}

.mobile-frame .v-tr-hoa-h-u-gi-i-tr {
  position: relative;
  flex: 1;
  font-family: var(--body-xs12-16-regular-font-family);
  font-weight: var(--body-xs12-16-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-xs12-16-regular-font-size);
  letter-spacing: var(--body-xs12-16-regular-letter-spacing);
  line-height: var(--body-xs12-16-regular-line-height);
  font-style: var(--body-xs12-16-regular-font-style);
}

.mobile-frame .mobile-div-3 {
  display: flex;
  width: 188px;
  height: 28px;
  align-items: center;
  gap: 4px;
  padding: 4px 6px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
}

.mobile-frame .mobile-text-wrapper {
  position: relative;
  flex: 1;
  font-family: var(--body-xs12-16-regular-font-family);
  font-weight: var(--body-xs12-16-regular-font-weight);
  color: var(--foundation-black-colornormal);
  font-size: var(--body-xs12-16-regular-font-size);
  letter-spacing: var(--body-xs12-16-regular-letter-spacing);
  line-height: var(--body-xs12-16-regular-line-height);
  font-style: var(--body-xs12-16-regular-font-style);
}

.mobile-frame .mobile-img {
  position: relative;
  width: 16px;
  height: 16px;
}

.mobile-frame .mobile-navbar {
  display: flex;
  align-items: flex-start;
  gap: 29px;
  padding: 10px 8px;
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--foundation-black-colornormal-active);
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media #{$sm-layout} {
    justify-content: center;
  }

  @media #{$large-mobile} {
    justify-content: flex-start;
  }

  @media #{$small-mobile} {
    justify-content: flex-start;
  }
}

.mobile-frame .mobile-text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--title-small-re-font-family);
  font-weight: var(--title-small-re-font-weight);
  color: #ffffff;
  font-size: var(--title-small-re-font-size);
  letter-spacing: var(--title-small-re-letter-spacing);
  line-height: var(--title-small-re-line-height);
  white-space: nowrap;
  font-style: var(--title-small-re-font-style);
}

.mobile-frame .mobile-div-4 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.mobile-frame .mobile-div-5 {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
  justify-content: space-between;

  @media #{$sm-layout} {
    padding: 0 10%;
  }

  @media #{$large-mobile} {
    position: static;
    padding: 0 4%;
  }

  @media #{$small-mobile} {
    padding: 0;
  }
}

.mobile-frame .mobile-div-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 48%;
  position: relative;
}

.mobile-frame .mobile-div-7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.mobile-frame .mobile-text-wrapper-3 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: var(--title-s16-21-bold-font-family);
  font-weight: var(--title-s16-21-bold-font-weight);
  color: var(--foundationprimary-colorlight);
  font-size: var(--title-s16-21-bold-font-size);
  letter-spacing: var(--title-s16-21-bold-letter-spacing);
  line-height: var(--title-s16-21-bold-line-height);
  font-style: var(--title-s16-21-bold-font-style);
}

.mobile-frame .mobile-div-8 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2px 2px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.mobile-frame .mobile-text-wrapper-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
}

.mobile-frame .mobile-text-wrapper-5 {
  position: relative;
  width: fit-content;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: var(--foundationprimary-colorlight-active);
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  white-space: nowrap;
  font-style: var(--body-m14-19-regular-font-style);
}

.mobile-frame .mobile-div-9 {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.mobile-frame .mobile-text-wrapper-6 {
  width: 50%;
  word-wrap: break-word;
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
}

.mobile-frame .mobile-div-10 {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.mobile-frame .mobile-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 7px 12px;
  position: relative;
  flex: 0 0 auto;
  all: unset;
  box-sizing: border-box;
  border-radius: 5px !important;
  color: white;
  font-size: var(--title-small-re-font-size);
  letter-spacing: var(--body-small-re-letter-spacing);
  line-height: var(--body-small-re-line-height);
  font-style: var(--body-small-re-font-style);
  background-color: var(--foundation-primary-color-normal) !important;
}

.mobile-frame .mobile-inter {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-small-re-font-family);
  font-weight: var(--body-small-re-font-weight);
  color: white;
  font-size: var(--title-small-re-font-size);
  letter-spacing: var(--body-small-re-letter-spacing);
  line-height: var(--body-small-re-line-height);
  font-style: var(--body-small-re-font-style);
  margin: 7px 15px;
}

.mobile-frame .mobile-div-11 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.mobile-frame .mobile-text-wrapper-7 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--title-s16-21-bold-font-family);
  font-weight: var(--title-s16-21-bold-font-weight);
  color: var(--foundationprimary-colorlight);
  font-size: var(--title-s16-21-bold-font-size);
  letter-spacing: var(--title-s16-21-bold-letter-spacing);
  line-height: var(--title-s16-21-bold-line-height);
  white-space: nowrap;
  font-style: var(--title-s16-21-bold-font-style);
}

.mobile-frame .mobile-div-12 {
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.mobile-frame .mobile-img-2 {
  position: relative;
  width: 18px;
  height: 18px;
}

.mobile-frame .mobile-text-wrapper-8 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-s13-17-regular-font-family);
  font-weight: var(--body-s13-17-regular-font-weight);
  color: var(--foundationprimary-colorlight-active);
  font-size: var(--body-s13-17-regular-font-size);
  letter-spacing: var(--body-s13-17-regular-letter-spacing);
  line-height: var(--body-s13-17-regular-line-height);
  white-space: nowrap;
  font-style: var(--body-s13-17-regular-font-style);
}

.mobile-frame .mobile-div-13 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  padding: 10px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--foundation-black-colordark-active);
  border-radius: 4px;
}

.mobile-frame .mobile-div-14 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 0px none;
  border-color: var(--foundationprimary-colorlight-active);
}

.mobile-frame .mobile-text-wrapper-9 {
  position: relative;
  flex: 1;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: var(--foundationprimary-colorlight);
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
}

.mobile-frame .mobile-text-wrapper-10 {
  position: relative;
  flex: 1;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: var(--foundationprimary-colorlight);
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
}

.mobile-frame .mobile-div-15 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  position: relative;
  flex: 0 0 auto;
  width: 100%;

  @media #{$sm-layout} {
    padding: 0 10%;
  }

  @media #{$large-mobile} {
    position: static;
    padding: 0 4%;
  }

  @media #{$small-mobile} {
    padding: 0;
  }
}

.mobile-frame .mobile-div-16 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.mobile-frame .mobile-img-3 {
  position: relative;
  flex: 0 0 auto;
}

.mobile-frame .mobile-div-17 {
  display: flex;
  width: 388px;
  height: 87px;
  align-items: center;
  gap: 16px;
  padding: 10px;
  position: relative;
  background-color: #000000cc;
  border-radius: 4px;
  // background-image: url(./img/frame-154.png);
  background-size: cover;
  background-position: 50% 50%;
}

.mobile-frame .mobile-image {
  position: relative;
  align-self: stretch;
  width: 85px;
  object-fit: cover;
}

.mobile-frame .mobile-div-18 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.mobile-frame .mobile-text-wrapper-11 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Open Sans-Bold", Helvetica;
  font-weight: 700;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 16px;
}

.mobile-frame .mobile-text-wrapper-12 {
  position: relative;
  align-self: stretch;
  font-family: var(--body-xs12-16-regular-font-family);
  font-weight: var(--body-xs12-16-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-xs12-16-regular-font-size);
  letter-spacing: var(--body-xs12-16-regular-letter-spacing);
  line-height: var(--body-xs12-16-regular-line-height);
  font-style: var(--body-xs12-16-regular-font-style);
}

.mobile-frame .mobile-div-19 {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.mobile-frame .mobile-text-wrapper-13 {
  position: relative;
  width: fit-content;
  font-family: var(--body-xs12-16-regular-font-family);
  font-weight: var(--body-xs12-16-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-xs12-16-regular-font-size);
  letter-spacing: var(--body-xs12-16-regular-letter-spacing);
  line-height: var(--body-xs12-16-regular-line-height);
  white-space: nowrap;
  font-style: var(--body-xs12-16-regular-font-style);
}

.mobile-frame .mobile-div-20 {
  display: flex;
  width: 388px;
  align-items: center;
  gap: 8px;
  padding: 10px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--foundationprimary-colorlight);
  border-radius: 4px;
}

.mobile-frame .tiktok-logo {
  position: relative;
  width: 65px;
  height: 65px;
}

.mobile-frame .mobile-div-21 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.mobile-frame .mobile-div-22 {
  display: flex;
  height: 28px;
  align-items: center;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.mobile-frame .mobile-text-wrapper-14 {
  position: relative;
  flex: 1;
  margin-top: -3px;
  margin-bottom: -1px;
  font-family: "Open Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--foundation-black-colordarker);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
}

.mobile-frame .mobile-inter-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 7px 12px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--foundationprimary-colornormal);
  border-radius: 8px;
  all: unset;
  box-sizing: border-box;
}

.mobile-frame .mobile-inter-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-small-re-font-family);
  font-weight: var(--body-small-re-font-weight);
  color: #ffffff;
  font-size: var(--body-small-re-font-size);
  letter-spacing: var(--body-small-re-letter-spacing);
  line-height: var(--body-small-re-line-height);
  white-space: nowrap;
  font-style: var(--body-small-re-font-style);
}

.mobile-frame .mobile-div-23 {
  display: flex;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.mobile-frame .mobile-div-24 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.mobile-frame .mobile-text-wrapper-15 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--body-m14-19-bold-font-family);
  font-weight: var(--body-m14-19-bold-font-weight);
  color: var(--foundation-black-colordarker);
  font-size: var(--body-m14-19-bold-font-size);
  letter-spacing: var(--body-m14-19-bold-letter-spacing);
  line-height: var(--body-m14-19-bold-line-height);
  white-space: nowrap;
  font-style: var(--body-m14-19-bold-font-style);
}

.mobile-frame .mobile-text-wrapper-16 {
  position: relative;
  width: fit-content;
  margin-top: -2px;
  font-family: var(--body-xxs10-14-regular-font-family);
  font-weight: var(--body-xxs10-14-regular-font-weight);
  color: var(--foundation-black-colordarker);
  font-size: var(--body-xxs10-14-regular-font-size);
  letter-spacing: var(--body-xxs10-14-regular-letter-spacing);
  line-height: var(--body-xxs10-14-regular-line-height);
  white-space: nowrap;
  font-style: var(--body-xxs10-14-regular-font-style);
}

.mobile-frame .mobile-div-25 {
  display: flex;
  flex-direction: column;
  height: 138px;
  align-items: center;
  gap: 9px;
  padding: 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
  border-radius: 4px;
  border: 1px solid;
  border-color: var(--foundationprimary-colorlight);
}

.mobile-frame .t-h-o-l-n-v-t-i-tr {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-m14-19-regular-font-size);
  text-align: center;
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  font-style: var(--body-m14-19-regular-font-style);
}

.mobile-frame .mobile-image-2 {
  position: relative;
  width: 246px;
  height: 67px;
}

.mobile-frame .mobile-div-26 {
  display: inline-flex;
  height: 83.69px;
  align-items: center;
  gap: 12px;
  position: relative;
}

.mobile-frame .mobile-p {
  position: relative;
  width: fit-content;
  font-family: var(--body-m14-19-regular-font-family);
  font-weight: var(--body-m14-19-regular-font-weight);
  color: #ffffff;
  font-size: var(--body-m14-19-regular-font-size);
  letter-spacing: var(--body-m14-19-regular-letter-spacing);
  line-height: var(--body-m14-19-regular-line-height);
  white-space: nowrap;
  font-style: var(--body-m14-19-regular-font-style);
}

.mobile-frame .mobile-image-3 {
  position: relative;
  width: 120px;
  height: 56.69px;
  object-fit: cover;
}

.mobile-frame .mobile-div-27 {
  display: flex;
  flex-direction: column;
  width: 388px;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.mobile-frame .mobile-vector {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -0.5px;
  object-fit: cover;
}

.mobile-frame .mobile-div-28 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.mobile-frame .mobile-element-pose-media {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Open Sans-SemiBold", Helvetica;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.mobile-frame .mobile-span {
  font-family: "Open Sans-SemiBold", Helvetica;
  font-weight: 600;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.mobile-frame .mobile-ellipse {
  position: relative;
  width: 4px;
  height: 4px;
  background-color: #d9d9d9;
  border-radius: 2px;
}

.mobile-frame .m-s-doanh-nghi-p {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Open Sans-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.mobile-frame .mobile-text-wrapper-17 {
  font-family: "Open Sans-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.mobile-frame .mobile-text-wrapper-18 {
  font-family: "Open Sans-SemiBold", Helvetica;
  font-weight: 600;
}