/**
 * Page
 */

.page {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.background--category {
  background-color: #222222;
}

.page-content,
.sidebar {
  display: table-cell;
}

.axil-post-separator {
  border-bottom: 1px solid rgba(173, 173, 173, 0.5);
}

.page-content {

  vertical-align: top;

  @media (min-width: 991px) {
    >.row {
      width: 100%;
    }
  }
}

.sidebar {
  width: 300px;
  position: relative;

  @media (max-width: 991px) {
    display: none;
  }

  //get last div
  >div:last-child {
    &.fixed {
      position: fixed;
      top: 90px;
      width: 300px;

      @media #{$lg-layout} {
        top: 120px;
      }
    }

    &.absolute {
      position: absolute;
      top: unset;
      bottom: 0;
      width: 300px;
    }
  }

}

.page-title {
  display: flex;
}

.bg-color-gray {
  background: #f4f4f4;
}