/*=========================
    Section Separation 
==========================*/

.bg-color-gradient {
  background: linear-gradient(180deg, #FEE9F9 0%, #EBEEFE 100%);
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.axil-section-gap {
  padding: 24px 0 50px 0;

  @media #{$md-layout} {
    padding: 10px 0 50px 0;
  }

  @media #{$sm-layout} {
    padding: 10px 0 30px 0;
  }
}

.axil-section-gapBottom {
  padding-bottom: 80px;

  @media #{$md-layout} {
    padding-bottom: 80px;
  }

  @media #{$sm-layout} {
    padding-bottom: 60px;
  }
}

.pb--165,
.pb--85 {
  @media #{$md-layout} {
    padding-bottom: 80px;
  }

  @media #{$sm-layout} {
    padding-bottom: 60px;
  }
}

.axil-section-gapTop {
  padding-top: 50px;
}

.axilil-service-area {
  &.axil-section-gap {
    &.layout-2 {
      padding-bottom: 160px;
      padding-top: 120px;

      @media #{$md-layout} {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      @media #{$sm-layout} {
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }
  }
}


.p--0 {
  padding: 0 !important;
}

.ptb--120 {
  @media #{$md-layout} {
    padding: 80px 0 !important;
  }

  @media #{$sm-layout} {
    padding: 60px 0 !important;
  }
}

.pb--165,
.pb--120,
.pb--85,
.pb--165 {
  @media #{$md-layout} {
    padding-bottom: 80px !important;
  }

  @media #{$sm-layout} {
    padding-bottom: 60px !important;
  }
}

.pb--70 {
  @media #{$sm-layout} {
    padding-bottom: 60px !important;
  }
}

.pl--0 {
  padding-left: 0 !important;
}

.pr--0 {
  padding-right: 0 !important;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.m--0 {
  margin: 0 !important;
}

.mr--0 {
  margin-right: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.ml--4 {
  margin-left: 4px !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mt--24 {
  margin-top: 24px !important;
}

.mt--27 {
  margin-top: 27px !important;
}

.mt--21 {
  margin-top: 21px !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.mb--27 {
  margin-bottom: 27px !important;
}

.pt--260 {
  padding-top: 260px;
}

.pt--250 {
  padding-top: 250px;
}

.pb--240 {
  padding-bottom: 240px;
}

.pb--220 {
  padding-bottom: 220px;
}

.plr--270 {
  padding: 0 270px;
}

.plr--300 {
  padding: 0 300px;
}

.plr--340 {
  padding: 0 340px;
}

.ptb--300 {
  padding: 300px 0;
}

.ptb--340 {
  padding: 340px 0;
}

.ptb--450 {
  padding: 450px 0;
}

.plr_dec--15 {
  margin: 0 -15px;
}

.pt--300 {
  padding-top: 300px;
}

@for $i from 1 through 40 {
  .p--#{5 * $i} {
    padding: 5px *$i !important;
  }
  .ptb--#{5 * $i} {
    padding: 5px *$i 0 !important;
  }

  .plr--#{5 * $i} {
    padding: 0 5px *$i !important;
  }

  .pt--#{5 * $i} {
    padding-top: 5px *$i !important;
  }

  .pb--#{5 * $i} {
    padding-bottom: 5px *$i !important;
  }

  .pl--#{5 * $i} {
    padding-left: 5px *$i !important;
  }

  .pr--#{5 * $i} {
    padding-right: 5px *$i !important;
  }

  .mt--#{5 * $i} {
    margin-top: 5px *$i !important;
  }

  .mb--#{5 * $i} {
    margin-bottom: 5px *$i !important;
  }

  .mr--#{5 * $i} {
    margin-right: 5px *$i !important;
  }

  .ml--#{5 * $i} {
    margin-left: 5px *$i !important;
  }
}


@media #{$laptop-device} {
  @for $i from 1 through 20 {
    .ptb_lp--#{5 * $i} {
      padding: 5px *$i 0;
    }

    .plr_lp--#{5 * $i} {
      padding: 0 5px *$i;
    }

    .pt_lp--#{5 * $i} {
      padding-top: 5px *$i;
    }

    .pb_lp--#{5 * $i} {
      padding-bottom: 5px *$i;
    }

    .pl_lp--#{5 * $i} {
      padding-left: 5px *$i;
    }

    .pr_lp--#{5 * $i} {
      padding-right: 5px *$i;
    }

    .mt_lp--#{5 * $i} {
      margin-top: 5px *$i;
    }

    .mb_lp--#{5 * $i} {
      margin-bottom: 5px *$i;
    }
  }
}


@media #{$lg-layout} {

  .pl_lg--0 {
    padding-left: 0 !important;
  }

  .pr_lg--0 {
    padding-right: 0 !important;
  }

  @for $i from 1 through 120 {
    .ptb_lg--#{5 * $i} {
      padding: 5px *$i 0 !important;
    }

    .plr_lg--#{5 * $i} {
      padding: 0 5px *$i !important;
    }

    .pt_lg--#{5 * $i} {
      padding-top: 5px *$i !important;
    }

    .pb_lg--#{5 * $i} {
      padding-bottom: 5px *$i !important;
    }

    .pl_lg--#{5 * $i} {
      padding-left: 5px *$i !important;
    }

    .pr_lg--#{5 * $i} {
      padding-right: 5px *$i !important;
    }

    .mt_lg--#{5 * $i} {
      margin-top: 5px *$i !important;
    }

    .mb_lg--#{5 * $i} {
      margin-bottom: 5px *$i !important;
    }

    .ml_lg--#{5 * $i} {
      margin-left: 5px *$i !important;
    }

  }
}

@media #{$md-layout} {

  .ptb_md--0 {
    padding: 0 !important;
  }

  .pl_md--0 {
    padding-left: 0 !important;
  }

  .pr_md--0 {
    padding-right: 0 !important;
  }

  .pt_md--0 {
    padding-top: 0 !important;
  }

  .pb_md--0 {
    padding-bottom: 0 !important;
  }

  .pb_md--15 {
    padding-bottom: 15px !important;
  }

  .mr_md--0 {
    margin-right: 0 !important;
  }

  .ml_md--0 {
    margin-left: 0 !important;
  }

  .mt_md--0 {
    margin-top: 0 !important;
  }

  .mb_md--0 {
    margin-bottom: 0 !important;
  }

  .ptb_md--250 {
    padding: 250px 0 !important;
  }

  @for $i from 1 through 120 {
    .ptb_md--#{5 * $i} {
      padding: 5px *$i 0 !important;
    }

    .plr_md--#{5 * $i} {
      padding: 0 5px *$i !important;
    }

    .pt_md--#{5 * $i} {
      padding-top: 5px *$i !important;
    }

    .pb_md--#{5 * $i} {
      padding-bottom: 5px *$i !important;
    }

    .pl_md--#{5 * $i} {
      padding-left: 5px *$i !important;
    }

    .pr_md--#{5 * $i} {
      padding-right: 5px *$i !important;
    }

    .mt_md--#{5 * $i} {
      margin-top: 5px *$i !important;
    }

    .mb_md--#{5 * $i} {
      margin-bottom: 5px *$i !important;
    }

  }
}

@media #{$sm-layout} {
  .ptb_sm--250 {
    padding: 250px 0 !important;
  }

  .ptb_sm--0 {
    padding: 0 !important;
  }

  .pl_sm--0 {
    padding-left: 0 !important;
  }

  .pr_sm--0 {
    padding-right: 0 !important;
  }

  .pt_sm--0 {
    padding-top: 0 !important;
  }

  .pb_sm--0 {
    padding-bottom: 0 !important;
  }

  .mr_sm--0 {
    margin-right: 0 !important;
  }

  .ml_sm--0 {
    margin-left: 0 !important;
  }

  .mt_sm--0 {
    margin-top: 0 !important;
  }

  .mb_sm--0 {
    margin-bottom: 0 !important;
  }

  .pt_sm--150 {
    padding-top: 150px !important;
  }

  .pb_sm--110 {
    padding-bottom: 110px !important;
  }

  @for $i from 1 through 120 {
    .ptb_sm--#{5 * $i} {
      padding: 5px *$i 0 !important;
    }

    .plr_sm--#{5 * $i} {
      padding: 0 5px *$i !important;
    }

    .pt_sm--#{5 * $i} {
      padding-top: 5px *$i !important;
    }

    .pb_sm--#{5 * $i} {
      padding-bottom: 5px *$i !important;
    }

    .pl_sm--#{5 * $i} {
      padding-left: 5px *$i !important;
    }

    .pr_sm--#{5 * $i} {
      padding-right: 5px *$i !important;
    }

    .mt_sm--#{5 * $i} {
      margin-top: 5px *$i !important;
    }

    .ml_sm--#{5 * $i} {
      margin-left: 5px *$i !important;
    }

    .mr_sm--#{5 * $i} {
      margin-right: 5px *$i !important;
    }

    .mb_sm--#{5 * $i} {
      margin-bottom: 5px *$i !important;
    }
  }

  .pl_sm--0 {
    padding-left: 0;
  }

  .pr_sm--0 {
    padding-right: 0;
  }

  .pt_sm--0 {
    padding-top: 0;
  }

  .pb_sm--0 {
    padding-bottom: 0;
  }

  .mr_sm--0 {
    margin-right: 0;
  }

  .ml_sm--0 {
    margin-left: 0;
  }

  .mt_sm--0 {
    margin-top: 0;
  }

  .mb_sm--0 {
    margin-bottom: 0;
  }

}


@media #{$large-mobile} {
  @for $i from 0 through 120 {
    .ptb_mobile--#{5 * $i} {
      padding: 5px *$i 0 !important;
    }

    .plr_mobile--#{5 * $i} {
      padding: 0 5px *$i !important;
    }

    .pt_mobile--#{5 * $i} {
      padding-top: 5px *$i !important;
    }

    .pb_mobile--#{5 * $i} {
      padding-bottom: 5px *$i !important;
    }

    .pl_mobile--#{5 * $i} {
      padding-left: 5px *$i !important;
    }

    .pr_mobile--#{5 * $i} {
      padding-right: 5px *$i !important;
    }

    .mt_mobile--#{5 * $i} {
      margin-top: 5px *$i !important;
    }

    .mb_mobile--#{5 * $i} {
      margin-bottom: 5px *$i !important;
    }

    .ml_mobile--#{5 * $i} {
      margin-left: 5px *$i !important;
    }
  }
}



@for $i from 1 through 20 {
  .slick-gutter-#{$i * 5} {
    margin-left: -#{$i * 5}px;
    margin-right: -#{$i * 5}px;

    .slick-slide {
      padding-left: #{$i * 5}px;
      padding-right: #{$i * 5}px;
    }
  }
}

@for $i from 1 through 200 {
  .h-#{$i} {
      height: #{$i}px;
  }
  .w-#{$i} {
      width: #{$i}px;
  }
}