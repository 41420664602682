/*----------------------
Post List Style  
-------------------------*/
.spinner-border {
  margin-left: 8px;
}

.slick-layout-wrapper {
  margin: 0 -15px;
}

.slick-single-layout {
  padding: 0 15px;
}

.content-block {
  .post-thumbnail {
    position: relative;

    a {
      display: block;
      overflow: hidden;
      border-radius: var(--radius);

      span {
        display: block !important;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &.image-rounded {
    .post-thumbnail {
      @extend %radius;
      overflow: hidden;

      img {
        @extend %radius;
        transition: 0.5s;
      }
    }

    // &:hover {
    //   .post-thumbnail {
    //     img {
    //       transform: scale(1.1);
    //     }
    //   }
    // }
  }

  .post-content {
    .title {
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      a {
        position: relative;
        display: inline;
        background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          from(currentColor),
          to(currentColor)
        );
        background-image: -webkit-linear-gradient(
          left,
          currentColor 0%,
          currentColor 100%
        );
        background-image: -o-linear-gradient(
          left,
          currentColor 0%,
          currentColor 100%
        );
        background-image: linear-gradient(
          to right,
          currentColor 0%,
          currentColor 100%
        );
        background-size: 0px 2px;
        background-position: 0px 95%;
        padding: 0.1% 0px;
        background-repeat: no-repeat;
        color: inherit;
        @extend %transition;

        &:hover {
          color: inherit;
        }
      }
    }
  }

  &:hover {
    .post-content {
      .title {
        color: var(--foundation-primary-color-normal-hover);
      }
    }
  }

  .post-meta {
    .hover-flip-item {
      span {
        &::before {
          color: var(--color-heading);
        }

        &::after {
          color: var(--color-primary);
        }
      }
    }
  }

  // Post Horizontal
  &.post-horizontal {
    display: flex;
    border: 1px solid var(--color-lighter);
    border-radius: var(--radius);
    padding: 35px 30px;
    height: 100%;
    align-items: center;

    @media #{$large-mobile} {
      display: block;

      .post-thumbnail {
        margin-top: 30px;
      }
    }

    .post-cat {
      margin-bottom: 22px;

      @media #{$lg-layout} {
        margin-bottom: 10px;
      }

      @media #{$sm-layout} {
        margin-bottom: 10px;
      }
    }

    .title {
      margin-bottom: 0;
    }

    .post-meta {
      margin-top: 48px;

      @media #{$lg-layout} {
        margin-top: 21px;
      }

      @media #{$sm-layout} {
        margin-top: 12px;
      }
    }

    .post-thumbnail {
      a {
        img {
          transition: 0.5s;
        }
      }
    }
  }

  // Content Derection Column
  &.content-direction-column {
    @extend %transition;

    .post-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 30px;

      @media #{$large-mobile} {
        flex-basis: 100%;
        // margin-top: 30px;
        padding-right: 0;
      }
    }

    .post-thumbnail {
      min-width: 250px;
      overflow: hidden;
      transition: 0.5s;

      @media #{$lg-layout} {
        min-width: 150px;
      }

      @media #{$sm-layout} {
        min-width: 160px;
      }

      @media #{$large-mobile} {
        flex-basis: 100%;
      }

      a {
        border-radius: 100%;

        span {
          display: block !important;
        }

        img {
          transition: 0.5s;
        }
      }
    }

    // &:hover {
    //   .post-thumbnail {
    //     img {
    //       transform: scale(1.1);
    //     }
    //   }
    // }

    &.is-active {
      background: var(--color-white);
    }
  }

  // Thub Rounded
  &.thumb-border-rounded {
    .post-thumbnail {
      a {
        img {
          border-radius: 100%;
        }
      }
    }
  }

  // Tab Post Style
  &.modern-post-style {
    border-radius: var(--radius);
    background: var(--color-lightest);
    padding: 50px 35px 0;
    overflow: hidden;

    @media #{$md-layout} {
      padding: 40px 20px 0;
    }

    &.text-center {
      .post-cat-list {
        justify-content: center;
      }
    }

    &.text-end {
      .post-cat-list {
        justify-content: flex-end;
      }
    }

    .post-content {
      padding-bottom: 50px;

      .title {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .post-thumbnail {
      margin: 0 -35px;
      position: relative;

      @media #{$md-layout} {
        margin: 0 -20px;
      }

      .round-shape {
        position: absolute;
        left: 0;
        top: -3px;
        z-index: 5;

        span {
          display: block !important;
        }

        img {
          background-position: center center;
          background-size: cover;
        }
      }

      a {
        img {
          transition: 0.5s;
        }
      }
    }

    // &:hover {
    //   .post-thumbnail {
    //     a {
    //       img {
    //         transform: scale(1.1);
    //       }
    //     }
    //   }
    // }
  }

  // Trend Post Style
  &.trend-post {
    position: relative;
    padding-top: 30px;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--color-lightest);

    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 2px;
      background: var(--color-primary);
      left: 0;
      bottom: 0;
      transition: 0.5s;
    }

    .post-thumbnail {
      position: absolute;
      top: 0;
      left: calc(100% + 30px);
      bottom: 0;
      opacity: 0;
      -webkit-transform: scale(0.95);
      -moz-transform: scale(0.95);
      -ms-transform: scale(0.95);
      -o-transform: scale(0.95);
      transform: scale(0.95);
      -webkit-transform-origin: center center;
      -moz-transform-origin: center center;
      -ms-transform-origin: center center;
      -o-transform-origin: center center;
      transform-origin: center center;
      will-change: transform;
      -webkit-transition: opacity 0.2s, -webkit-transform 1s;
      transition: opacity 0.2s, -webkit-transform 1s;
      -o-transition: opacity 0.2s, -o-transform 1s;
      -moz-transition: opacity 0.2s, transform 1s, -moz-transform 1s;
      transition: opacity 0.2s, transform 1s;
      transition: opacity 0.2s, transform 1s, -webkit-transform 1s,
        -moz-transform 1s, -o-transform 1s;
      min-width: 411px;

      img {
        @extend %radius;
      }

      @media #{$lg-layout} {
        min-width: 280px;
      }

      @media #{$md-layout} {
        position: static;
        left: calc(100% + 30px);
        top: auto;
        min-width: auto;
        transform: none;
        opacity: 1;
        visibility: visible;
        margin-top: 40px;
      }

      @media #{$sm-layout} {
        position: static;
        left: calc(100% + 30px);
        top: auto;
        min-width: auto;
        transform: none;
        opacity: 1;
        visibility: visible;
        margin-top: 40px;
      }
    }

    .post-content {
      .post-cat {
        margin-bottom: 15px;
      }

      .title {
        margin-bottom: 0;
      }

      .post-meta-wrapper {
        margin-top: 22px;
      }
    }

    &.is-active {
      &::after {
        width: 100%;

        @media #{$md-layout} {
          width: 0;
        }

        @media #{$sm-layout} {
          width: 0;
        }
      }

      .post-thumbnail {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        height: auto;

        @media #{$md-layout} {
          transform: none;
        }

        @media #{$sm-layout} {
          transform: none;
        }
      }
    }

    &:last-child {
      &.is-active {
        .post-thumbnail {
          height: auto;
          opacity: 1;
          visibility: visible;
          top: auto;
          bottom: 0;
          transform: translateY(0) scale(1);
        }
      }
    }

    &:first-child {
      &.is-active {
        .post-thumbnail {
          top: 0;
          transform: translateY(0) scale(1);
        }
      }
    }

    &:hover {
      .post-content {
        .title {
          a {
            background-size: 0px 95%;
          }
        }
      }
    }
  }

  // Post With Order List
  &.post-order-list {
    .post-inner {
      display: flex;

      .post-order-list {
        padding-right: 90px;
        font-size: var(--font-size-b2);
        line-height: 20px;
        color: var(--color-body);
        display: inline-block;
        font-weight: var(--p-medium);

        @media #{$sm-layout} {
          padding-right: 20px;
        }
      }
    }
  }

  // Post Grid
  &.post-grid {
    position: relative;
    height: 100%;

    .content {
      position: relative;
      z-index: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: var(--color-tertiary);
    }

    .post-thumbnail {
      min-height: 240px;
      overflow: hidden;

      a {
        height: 100%;

        img {
          @extend %radius;
          width: 100%;
          overflow: hidden;
          transition: 0.5s;

          @media #{$large-mobile} {
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }

    .post-thumbnail-sub {
      min-height: 113px;
      overflow: hidden;

      a {
        min-height: 113px;
        position: relative;
        display: block;
        overflow: hidden;

        img {
          @extend %radius;
          width: 100%;
          overflow: hidden;
          transition: 0.5s;

          @media #{$large-mobile} {
            border-radius: 10px;
          }
        }
      }
    }

    // &:hover {
    //   .post-thumbnail {
    //     a {
    //       img {
    //         transform: scale(1.1);
    //       }
    //     }
    //   }
    // }

    .post-grid-content {
      position: absolute;
      width: auto;
      z-index: 2;
      left: 0;
      right: 0;

      // @media #{$lg-layout} {
      //   padding: 0 25px;
      // }

      // @media #{$md-layout} {
      //   padding: 0 25px;
      // }

      // @media #{$sm-layout} {
      //   padding: 0 25px;
      // }

      @media #{$large-mobile} {
        position: static;
        padding: 0;
      }

      .post-content {
        background: var(--color-white);
        @extend %radius;

        @media #{$large-mobile} {
          border-radius: 0 0 10px 10px;
        }

        .title {
          margin-bottom: 0;

          @media #{$sm-layout} {
            line-height: 24px;
          }

          a {
            @extend %transition;
          }
        }

        h3 {
          &.title {
            @media #{$sm-layout} {
              font-size: 20px;
              line-height: 26px;
            }
          }
        }

        .post-meta-wrapper {
          margin-top: 30px;
        }
      }
    }

    &.post-grid-large {
      .post-grid-content {
        padding: 0 65px;

        @media #{$lg-layout} {
          padding: 0 25px;
        }

        @media #{$md-layout} {
          padding: 0 25px;
        }

        @media #{$sm-layout} {
          padding: 0 25px;
        }

        @media #{$large-mobile} {
          padding: 0;
        }

        .post-content {
          .post-cat {
            margin-bottom: 20px;
          }
        }
      }
    }

    &.post-grid-transparent {
      padding-bottom: 0;

      .post-grid-content {
        padding: 0 40px;
        padding-bottom: 40px;
        position: absolute;

        @media #{$sm-layout} {
          padding: 0 20px;
          padding-bottom: 20px;
        }

        .post-content {
          background: transparent !important;
          padding: 0;

          .post-cat {
            .post-cat-list {
              a {
                color: var(--color-lightest);
                @extend %transition;

                &.hover-flip-item-wrapper {
                  span {
                    &::before {
                      color: var(--color-white);
                    }

                    &::after {
                      color: var(--color-primary);
                    }
                  }
                }

                &:hover {
                  color: var(--color-primary);
                }
              }
            }
          }

          .title {
            color: var(--color-white);
          }
        }
      }
    }

    &.post-grid-small {
      .post-grid-content {
        padding: 0 30px;
        padding-bottom: 20px;

        @media #{$md-layout} {
          padding: 0 20px;
          padding-bottom: 20px;
        }

        @media #{$sm-layout} {
          padding: 20px 20px;
        }
      }
    }
  }

  // Post List View
  &.post-list-view {
    // display: flex;

    @media #{$sm-layout} {
      flex-wrap: wrap;
    }

    .post-thumbnail {
      height: 250px;
      overflow: hidden;

      &.sub {
        height: 120px;
      }

      &.category {
        min-height: 600px;
        @media #{$sm-layout} {
          min-height: 300px;
        }
      }

      &.category-sub {
        min-height: 155px;
        width: 100% !important;
        flex-basis: 100% !important;
      }

      img {
        transition: 0.5s;
      }

      @media #{$sm-layout} {
        min-width: auto;
        margin-right: 0;
        flex-basis: 30%;
        padding-right: 20px;
        &.category {
          padding-right: 0px;
        }
        min-height: 300px;
      }

      @media #{$large-mobile} {
        flex-basis: 45%;
        padding-right: 0;
        min-width: 100%;
      }

      a {
        display: block;
        height: 100%;

        @media #{$large-mobile} {
          border-radius: var(--radius) var(--radius) var(--radius) var(--radius);
        }

        img {
          width: 100%;
          @extend %radius;
          object-fit: cover;
          height: 100%;

          @media #{$large-mobile} {
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }

    .post-content {
      // padding: 0px 16px;

      &:hover {
        h5 {
          color: var(--foundation-primary-color-normal-hover) !important;

          a {
            color: var(--foundation-primary-color-normal-hover) !important;
          }
        }
      }

      h5 {
        @extend %transition;

        a {
          @extend %transition;
        }
      }

      @extend %transition;
      flex-grow: 1;

      @media #{$large-mobile} {
        flex-basis: 55%;
      }

      .title {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        &.unline-clamp {
          -webkit-line-clamp: 10;
        }
      }

      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: var(--color-tertiary);
      }

      .post-cat {
        margin-bottom: 15px;

        .post-cat-list {
          display: flex;
        }
      }

      .post-meta-wrapper {
        margin-top: 46px;

        @media #{$lg-layout} {
          margin-top: 15px;
        }

        @media #{$sm-layout} {
          margin-top: 15px;
        }

        .post-meta {
          @media #{$sm-layout} {
            margin-bottom: 15px;
          }
        }
      }
    }

    .post-content-sub {
      .line--clamp-4 {
        position: relative;
        list-style: none;
        padding: 0 0 0 20px;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          display: block;
          content: "";
          height: 6px;
          width: 6px;
          background: var(--foundation-primary-color-normal);
        }

        h5 {
          display: inline-block;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;

          a {
            color: var(--color-heading);
          }

          &:hover {
            a {
              color: var(--foundation-primary-color-normal-hover) !important;
            }
          }
        }

        -webkit-line-clamp: 4;
      }
    }

    &:first-child {
      margin-top: 0 !important;
    }

    // &.is-active,
    // &:hover {
    //   .post-content {
    //     box-shadow: var(--shadow-primary);
    //     background: var(--color-white);
    //     border: 1px solid var(--color-white);
    //   }
    // }

    // &:hover {
    //   .post-thumbnail {
    //     img {
    //       transform: scale(1.1);
    //     }
    //   }
    // }

    &.sticky {
      .post-content {
        border: 0 none;
      }

      &:hover {
        .post-content {
          background: transparent;
          box-shadow: none;
        }
      }
    }

    &.format-quote {
      blockquote {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &.with-bg-solid {
      .post-content {
        border: 1px solid var(--color-white);
        background: var(--color-white);
      }
    }
  }

  // Hot Post Style
  &.hot-list-view {
    display: flex;

    @media #{$sm-layout} {
      flex-wrap: wrap;
      min-height: 125px;

      &.axil-control {
        &.border--top {
          border-top: var(--border-width) solid var(--color-lightest);
        }

        &.parent {
          //not first child
          &:not(:first-child) {
            border-top: var(--border-width) solid var(--color-lightest);
          }
        }
      }
    }

    .post-thumbnail {
      min-width: 220px;
      margin-right: 10px;
      @extend %radius;
      max-width: 220px;
      overflow: hidden;

      img {
        transition: 0.5s;
      }

      @media #{$lg-layout} {
        min-width: 223px;
      }

      @media #{$sm-layout} {
        min-width: auto;
        margin-right: 0;
        flex-basis: 50%;
        padding-right: 8px;
      }

      @media #{$large-mobile} {
        flex-basis: 50%;
        padding-right: 0;
      }

      a {
        display: block;
        height: 100%;

        @media #{$large-mobile} {
          border-radius: var(--radius) var(--radius) var(--radius) var(--radius);
        }

        img {
          width: 100%;
          @extend %radius;
          object-fit: cover;
          height: 100%;

          @media #{$large-mobile} {
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }

    > .post-content {
      min-height: 160px;

      @media #{$large-mobile} {
        min-height: unset;
      }
    }

    .post-content {
      padding: 0 0 0 8px;
      @extend %transition;
      flex-grow: 1;

      @media #{$sm-layout} {
        flex-basis: 50%;
        padding-left: 8px;
      }

      @media #{$large-mobile} {
        flex-basis: 50%;
        border-radius: 0 0 10px 10px;
        padding: 0 0 0 8px;
      }

      .title {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        a {
          @extend %transition;
        }
      }

      .post-cat {
        margin-bottom: 15px;

        .post-cat-list {
          display: flex;
        }
      }

      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-weight: var(--p-light);
        color: var(--color-tertiary);

        &.unline-clamp {
          -webkit-line-clamp: 10;
        }
      }

      .post-meta-wrapper {
        margin-top: 46px;

        @media #{$lg-layout} {
          margin-top: 15px;
        }

        @media #{$sm-layout} {
          margin-top: 15px;
        }

        .post-meta {
          @media #{$sm-layout} {
            margin-bottom: 15px;
          }
        }
      }
    }

    // &:first-child {
    //   margin-top: 0 !important;
    // }

    // &.is-active,
    // &:hover {
    //   .post-content {
    //     box-shadow: var(--shadow-primary);
    //     background: var(--color-white);
    //     border: 1px solid var(--color-white);
    //   }
    // }

    // &:hover {
    //   .post-thumbnail {
    //     img {
    //       transform: scale(1.1);
    //     }
    //   }
    // }

    &.sticky {
      .post-content {
        border: 0 none;
      }

      &:hover {
        .post-content {
          background: transparent;
          box-shadow: none;
        }
      }
    }

    &.format-quote {
      blockquote {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &.with-bg-solid {
      .post-content {
        border: 1px solid var(--color-white);
        background: var(--color-white);
      }
    }
  }

  // Post Sidebar
  &.post-medium {
    display: flex !important;

    &:last-child {
      margin-bottom: 0;
    }

    .post-thumbnail {
      width: 120px;
      margin-right: 12px;
      min-width: 120px;
      @extend %radius;
      overflow: hidden;
      height: 80px;

      @media #{$lg-layout} {
        width: 110px;
        margin-right: 10px;
        min-width: 110px;
      }

      a {
        img {
          width: 100%;
          @extend %radius;
          transition: 0.5s;
        }
      }
    }

    .post-content {
      .post-cat {
        margin-bottom: 15px;

        .post-cat-list {
          a {
            &.hover-flip-item-wrapper {
              display: flex;
            }
          }
        }
      }

      .title {
        margin-bottom: 10px;
        font-weight: var(--p-semi-bold);
      }
    }

    &.post-medium-border {
      border-bottom: 2px solid var(--color-lighter);
      padding: 30px 0;

      &.border-thin {
        border-width: 1px;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0 none;
      }

      &:first-child {
        padding-top: 0;
      }
    }

    // &:hover {
    //   .post-thumbnail {
    //     a {
    //       img {
    //         transform: scale(1.1);
    //       }
    //     }
    //   }
    // }
  }

  // Post New
  &.post-new-view {
    display: flex;

    @media #{$sm-layout} {
      flex-wrap: wrap;
    }

    .post-thumbnail {
      min-width: 350px;
      margin-right: 20px;
      @extend %radius;
      max-width: 350px;
      overflow: hidden;

      img {
        transition: 0.5s;
      }

      @media #{$lg-layout} {
        min-width: 223px;
      }

      @media #{$sm-layout} {
        min-width: auto;
        margin-right: 0;
        flex-basis: 50%;
        padding-right: 20px;
      }

      @media #{$large-mobile} {
        flex-basis: 100%;
        padding-right: 0;
        border-radius: 10px 10px 0 0;
        min-width: 100%;
        min-height: 218px;
      }

      a {
        display: block;
        height: 100%;

        @media #{$large-mobile} {
          border-radius: var(--radius) var(--radius) 0 0;
        }

        img {
          width: 100%;
          @extend %radius;
          object-fit: cover;
          height: 100%;

          @media #{$large-mobile} {
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }

    .post-content {
      // border: 1px solid var(--color-lightest);
      min-height: 250px;
      padding: 10px 0px;
      @extend %radius;
      @extend %transition;
      flex-grow: 1;

      @media #{$sm-layout} {
        flex-basis: 50%;
      }

      @media #{$large-mobile} {
        flex-basis: 100%;
        border-radius: 0 0 10px 10px;
        min-height: unset;
        padding: 20px 0 0 0;
      }

      .title {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: 0.5s;
      }

      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-weight: var(--p-light);
        color: var(--color-tertiary);
      }

      .post-cat {
        // margin-top: 15px;
        // margin-bottom: 15px;

        .post-cat-list {
          display: flex;
        }

        @media #{$large-mobile} {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      .post-meta-wrapper {
        margin-top: 46px;

        @media #{$lg-layout} {
          margin-top: 15px;
        }

        @media #{$sm-layout} {
          margin-top: 15px;
        }

        .post-meta {
          @media #{$sm-layout} {
            margin-bottom: 15px;
          }
        }
      }
    }

    &:first-child {
      margin-top: 0 !important;
    }

    &.is-active,
    // &:hover {
    //     .post-content {
    //         box-shadow: var(--shadow-primary);
    //         background: var(--color-white);
    //         border: 1px solid var(--color-white);
    //     }
    // }

    // &:hover {
    //   .post-thumbnail {
    //     img {
    //       transform: scale(1.1);
    //     }
    //   }
    // }

    &.sticky {
      .post-content {
        border: 0 none;
      }

      &:hover {
        .post-content {
          background: transparent;
          box-shadow: none;
        }
      }
    }

    &.format-quote {
      blockquote {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &.with-bg-solid {
      .post-content {
        border: 1px solid var(--color-white);
        background: var(--color-white);
      }
    }
  }

  // Post Default
  &.post-default {
    .post-thumbnail {
      overflow: hidden;
      border-radius: var(--radius);

      a {
        img {
          transition: 0.5s;
        }
      }
    }

    .post-content {
      // margin-top: 10px;

      .title {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        transition: 0.5s;
      }

      .post-meta-wrapper {
        margin-top: 30px;
      }
    }

    // &:hover {
    //   .post-thumbnail {
    //     a {
    //       img {
    //         transform: scale(1.1);
    //       }
    //     }
    //   }
    // }
  }

  &.sticky {
    background: var(--color-lightest);
    border-radius: var(--radius);
    border-top: 4px solid var(--color-primary);
  }

  &.post-overlay-bottom {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      opacity: 0.8;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 240px;
      background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1a1a1a 100%);
      border-radius: 0 0 10px 10px;
      z-index: 1;

      @media #{$md-layout} {
        height: 100%;
      }

      @media #{$sm-layout} {
        height: 100%;
      }
    }
  }
}

// Featured Images
.featured-post {
  .content-block {
    background: #ffffff;
    border-radius: var(--radius);

    .post-content {
      padding: 30px;
    }

    .post-thumbnail {
      position: relative;

      a {
        border-radius: 0 0 10px 10px;
        overflow: hidden;

        img {
          border-radius: 0 0 10px 10px;
          transition: 0.5s;
        }
      }
    }

    // &:hover {
    //   .post-thumbnail {
    //     a {
    //       img {
    //         transform: scale(1.1);
    //       }
    //     }
    //   }
    // }
  }
}

.content-block {
  .post-thumbnail {
    position: relative;

    .review-count {
      position: absolute;
      background: #d93e40;
      top: 20px;
      left: 40px;
      padding: 8px;
      border-radius: 6px;

      span {
        font-size: 18px;
        line-height: 22px;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}

/* --------------------
Post Category  
-----------------------*/
.post-content {
  .post-cat {
    .post-cat-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -8px;

      a {
        @extend %foundation-primary-color-normal;
        @extend %transition;
        display: flex;
        font-size: var(--font-size-b12);
        line-height: 20px;
        margin: 15px 10px 15px 10px;
        position: relative;
        font-weight: var(--p-semi-bold);
        @extend %transition;

        &.hover-flip-item-wrapper {
          span {
            &::after {
              color: var(--color-primary);
            }
          }
        }

        &:hover {
          @extend %foundation-primary-color-normal-hover;
        }

        &::after {
          content: ",";
          position: absolute;
          right: 0;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .post-cat-more {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -8px 0 -8px;

    a {
      @extend %foundation-primary-color-normal;
      @extend %transition;
      display: flex;
      font-size: var(--font-size-b12);
      line-height: 20px;
      margin: 0 8px 0 8px;
      position: relative;
      font-weight: var(--p-semi-bold);
      @extend %transition;

      &.hover-flip-item-wrapper {
        span {
          &::after {
            color: var(--color-primary);
          }
        }
      }

      &:hover {
        @extend %foundation-primary-color-normal-hover;
      }

      &::after {
        content: ",";
        position: absolute;
        right: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

.hot-tag {
  .post-cat {
    .post-cat-list {
      span {
        font-size: var(--font-size-b4);
        line-height: var(--font-size-b4);
        margin: 10px;
        position: relative;
        font-weight: var(--p-medium);
        @extend %transition;
        background-color: var(--foundation-primary-color-light);
        color: var(--foundation-primary-color-normal);
        padding: 3px 6px;
        border-radius: var(--border-width);
      }
    }
  }

  .post-cat-more {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -8px 0 -8px;

    a {
      @extend %foundation-primary-color-normal;
      @extend %transition;
      display: flex;
      font-size: var(--font-size-b3);
      line-height: 20px;
      margin: 0 8px 0 8px;
      position: relative;
      font-weight: var(--p-medium);
      @extend %transition;

      &.hover-flip-item-wrapper {
        span {
          &::after {
            color: var(--color-primary);
          }
        }
      }

      &:hover {
        @extend %foundation-primary-color-normal-hover;
      }

      &::after {
        content: ",";
        position: absolute;
        right: 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

/*-----------------------
    Post Meta Date  
------------------------*/
.post-meta-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media #{$large-mobile} {
    display: block;
  }

  ul {
    &.social-share-transparent {
      @media #{$large-mobile} {
        margin-top: 12px;
        justify-content: flex-start !important;
      }
    }
  }

  .hover-flip-item {
    span {
      &::before {
        color: var(--color-heading);
      }

      &::after {
        color: var(--color-primary);
      }
    }
  }
}

.post-meta {
  display: flex;
  align-items: center;

  .post-author-avatar {
    min-width: 50px;
    margin-right: 15px;

    img {
      width: 100%;
    }

    &.border-rounded {
      border-radius: 100%;

      img {
        border-radius: 100%;
      }
    }
  }

  .post-author-name {
    margin-top: 8px;
    font-weight: var(--p-medium);
    color: var(--color-extra01);

    a {
      @extend %transition;
    }
  }
}

ul {
  &.post-meta-list {
    display: flex;
    flex-wrap: wrap;
    @extend %liststyle;
    margin: 0px -10px;

    li {
      color: var(--color-extra01);
      font-size: var(--font-size-b2);
      line-height: 18px;
      margin: 10px 10px 5px 10px;
      position: relative;

      &.li-more {
        margin: 0px 10px 10px 10px;

        @media #{$large-mobile} {
          margin: 5px 10px 10px 10px;
        }
      }

      &::after {
        right: -11px;
        position: absolute;
        background: var(--color-extra01);
        width: 4px;
        height: 4px;
        border-radius: 100%;
        top: 50%;
        transform: translateY(-50%);
        content: "";
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

.hover-flip-item-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  transition: opacity 0.2s;
  display: flex;
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .hover-flip-item-wrapper {
    opacity: 1;
    text-align: left;
  }
}

.hover-flip-item {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .hover-flip-item span {
    display: inline-block;
    position: relative;
    z-index: 1;
    color: transparent;
    -webkit-transition: -webkit-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: -o-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1),
      -moz-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1),
      -webkit-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1),
      -moz-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1),
      -o-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .hover-flip-item span:after {
    content: attr(data-text);
    display: block;
    position: absolute;
    color: var(--color-primary);
  }

  .hover-flip-item span:before {
    content: attr(data-text);
    display: block;
    position: absolute;
    color: var(--foundation-primary-color-normal);
  }

  .hover-flip-item span:before {
    top: 0;
    -webkit-transform: skewY(0);
    -moz-transform: skewY(0);
    -ms-transform: skewY(0);
    -o-transform: skewY(0);
    transform: skewY(0);
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transition: -webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: -o-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -moz-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -moz-transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -o-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
  }

  .hover-flip-item span:after {
    top: 105%;
    -webkit-transform: skewY(7deg);
    -moz-transform: skewY(7deg);
    -ms-transform: skewY(7deg);
    -o-transform: skewY(7deg);
    transform: skewY(7deg);
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    -webkit-transition: -webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: -o-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -moz-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -moz-transform 2s cubic-bezier(0.19, 1, 0.22, 1),
      -o-transform 2s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.hover-flip-item-wrapper:hover {
  opacity: 1;
}

.hover-flip-item-wrapper:hover .hover-flip-item span {
  -webkit-transform: translateY(-105%);
  -moz-transform: translateY(-105%);
  -ms-transform: translateY(-105%);
  -o-transform: translateY(-105%);
  transform: translateY(-105%);
}

@media (pointer: coarse) {
  .hover-flip-item-wrapper:hover .hover-flip-item span {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

.hover-flip-item-wrapper:hover .hover-flip-item span:before {
  -webkit-transform: skewY(7deg);
  -moz-transform: skewY(7deg);
  -ms-transform: skewY(7deg);
  -o-transform: skewY(7deg);
  transform: skewY(7deg);
}

@media (pointer: coarse) {
  .hover-flip-item-wrapper:hover .hover-flip-item span:before {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

.hover-flip-item-wrapper:hover .hover-flip-item span:after {
  -webkit-transform: skewY(0);
  -moz-transform: skewY(0);
  -ms-transform: skewY(0);
  -o-transform: skewY(0);
  transform: skewY(0);
}

@media (pointer: coarse) {
  .hover-flip-item-wrapper:hover .hover-flip-item span:after {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
  }
}

.widget-model {
  .header {
    background: linear-gradient(180deg, #fee9f9 0%, #ebeefe 100%);
    .title {
      font-size: 28px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
    }
    .subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
    }
  }
  .info {
    display: flex;
    gap: 10px;
    .item {
      flex: 1;
      &.item-left {
        background: #fee9f9;
      }
      .label {
        font-size: 15px;
        font-weight: 700;
        line-height: 19px;
        text-align: left;
        color: inherit !important;
      }
      .value {
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        text-align: left;
      }
      &.item-right {
        display: flex;
        flex-direction: column;
        div {
          flex: 1;
        }
        .height {
          background: #fee9f9;
        }
        .more {
          background: #ebeefe;
          transition: 0.3s;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &:hover {
            background: var(--foundation-primary-color-normal);
            color: white !important;
          }
        }
      }
    }
  }
  .modal {
    display: none;
    opacity: 0;
    transition: 0.5s;
    &.active {
      display: flex;
      opacity: 1;
    }
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    .modal-content {
      background: url("https://cdn.blueseed.tv/bu6-tech/2024/7/pose/background.jpg") repeat 0 0;
      background-size: 100%;
      background-color: transparent;
      width: 80%;
      max-width: 970px;
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      overflow: auto;
    }
  }

  .relative {
    width: 80%;
    max-width: 970px;
    height: 80%;
    margin: auto;
  }

  .close {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-weight: 700;
    color: black;
    border-radius: 50%;
    padding-left: 6px;
    background: white;
    width: 30px;
    height: 30px;
    transition: 0.3s;
    &:hover {
      background: var(--foundation-primary-color-normal);
      color: white !important;
    }
    p {
      font-size: 30px;
      line-height: 25px;
      color: inherit !important;
    }
  }
}

.timeline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 10px 30px 30px;
  border-top: 2px solid #e5e5e5;
}

.event {
  text-align: center;
  position: relative;
  height: 100px;
  flex: 1 1 0;
}

.event::before {
  content: '+';
  display: block;
  font-size: 24px;
  color: #ff4081;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
}

.event .year {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.event .description {
  font-size: 14px;
  color: #666;
  margin-top: 60px;
}

.line {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ff4081;
  z-index: -1;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: unset !important;
  width: 50%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  border: 3px solid #ff4081;
  border-radius: 10px;
  overflow: hidden;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-pagination {
  bottom: -30px !important;
}

.swiper-pagination-bullet-active {
  background: #ff4081 !important;
  width: 40px !important;
  border-radius: 10px !important;
}
