/*--------------------------
Navigation Css  
---------------------------*/
.mainmenu-nav {
  ul {
    &.mainmenu {
      @extend %liststyle;
      display: flex;
      margin: 0;
      padding: 0;
      justify-content: center;
      flex-wrap: wrap;

      &.mobile-menu {
        flex-wrap: nowrap;

        @media #{$sm-layout} {
          overflow-x: scroll;
          overflow-y: hidden;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        justify-content: flex-start;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      >li {
        margin: 0 18px;
        position: relative;

        @media #{$laptop-device} {
          margin: 0 12px;
        }

        @media #{$lg-layout} {
          margin: 0 12px;
        }

        >a {
          font-size: var(--font-size-b2);
          line-height: var(--line-height-b2);
          color: var(--color-heading);
          font-weight: var(--p-medium);
          @extend %transition;
          height: 60px;
          display: block;
          line-height: 60px;
          position: relative;
          white-space: nowrap;

          &:hover {
            @extend %foundation-primary-color-normal-hover;

            @media #{$sm-layout} {
              color: var(--color-heading);
            }
          }

          @media #{$lg-layout} {
            height: 50px;
            line-height: 55px;
          }

          @media #{$md-layout} {
            height: 50px;
            line-height: 55px;
          }

          @media #{$sm-layout} {
            height: 50px;
            line-height: 55px;
          }
        }

        &:hover {
          >a {
            &::after {
              width: 100%;
            }
          }

          @media #{$sm-layout} {
            >a {
              &::after {
                width: 0;
              }
            }
          }
        }

        &.menu-item-has-children {
          .axil-submenu {
            @extend %liststyle;
            position: absolute;
            top: 100%;
            left: 0;
            background: #ffffff;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            min-width: 200px;
            // padding: 0 10px;
            border-radius: 0 0 10px 10px;
            box-shadow: var(--shadow-primary);
            @extend %transition;

            li {

              //not first-child
              &:not(:first-child) {
                border-top: 1px solid #E7E7E7;
                padding-top: 10px;
              }

              a {
                font-size: var(--font-size-b1);
                line-height: var(--line-height-b1);
                color: var(--color-heading);
                font-weight: var(--p-medium);
                @extend %transition;

                &.hover-flip-item-wrapper {
                  span {
                    &::before {
                      color: var(--color-midgray);
                    }

                    &::after {
                      color: var(--color-primary);
                    }
                  }
                }
              }

              &:hover {
                >a {
                  color: var(--foundation-primary-color-normal-hover)
                }

                @media #{$sm-layout} {
                  >a {
                    color: var(--color-midgray);
                  }
                }
              }
            }
          }

          &:hover {
            .axil-submenu {
              opacity: 1;
              visibility: visible;
              z-index: 9;
            }

            @media #{$sm-layout} {
              .axil-submenu {
                opacity: 0;
                visibility: hidden;
                z-index: -1;
              }
            }
          }
        }

        // Mega Menu 
        &.megamenu-wrapper {
          position: static;

          .megamenu-sub-menu {
            @extend %liststyle;
            box-sizing: border-box;
            position: absolute;
            background: #fff;
            // width: 100%;
            left: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
            min-width: 1230px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 0 0 10px 10px;
            box-shadow: var(--shadow-primary);

            @media #{$md-layout} {
              min-width: 100%;
            }

            @media #{$lg-layout} {
              min-width: 100%;
            }

            @media #{$sm-layout} {
              min-width: 100%;
            }

            .megamenu-item {
              display: flex;
              margin: 0;

              .axil-vertical-nav {
                min-width: 279px;
                width: auto;
                border-right: 1px solid #CED0D4;
                padding: 30px;
                @extend %liststyle;

                .vertical-nav-item {
                  margin: 10px 0;

                  a {
                    color: var(--color-midgray);
                    font-size: 16px;
                    line-break: 20px;
                    display: block;

                    &.hover-flip-item-wrapper {
                      span {
                        &::before {
                          color: var(--color-midgray);
                        }

                        &::after {
                          color: var(--color-primary);
                        }
                      }
                    }
                  }

                  &.active {
                    a {
                      color: var(--foundation-primary-color-normal);

                      &.hover-flip-item-wrapper {
                        span {
                          &::before {
                            color: var(--color-primary);
                          }
                        }
                      }
                    }
                  }

                  &:first-child {
                    margin-top: 0;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }

              .axil-vertical-nav-content {
                width: 83.589%;
                padding: 30px;
              }
            }
          }

          &:hover {
            .megamenu-sub-menu {
              opacity: 1;
              visibility: visible;
            }

            @media #{$sm-layout} {
              .megamenu-sub-menu {
                opacity: 0;
                visibility: hidden;
              }
            }
          }
        }

      }
    }
  }
}