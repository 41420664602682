.button-content {
	grid-area: content;
	display: flex;
	flex-direction: column;
	align-items: center;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.cursor {
	display: none;
}

@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		z-index: 9;
	}

	.cursor__inner {
		fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
	}

	.credits {
		padding-left: 25vw;
	}
}

/*---------------------------------------------------*/
/*	09) SHANE CURSOR
/*---------------------------------------------------*/

.mouse-cursor {
	position: fixed;
	left: 0;
	top: 0;
	pointer-events: none;
	border-radius: 50%;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.cursor-inner {
	margin-left: -3px;
	margin-top: -3px;
	width: 6px;
	height: 6px;
	z-index: 10000001;
	background-color: var(--color-primary);
	-webkit-transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out;
	transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out;
}

.cursor-inner.cursor-hover {
	margin-left: -30px;
	margin-top: -30px;
	width: 60px;
	height: 60px;
	background-color: #CED0D4;
	opacity: .3;
}

.cursor-outer {
	margin-left: -15px;
	margin-top: -15px;
	width: 30px;
	height: 30px;
	border: 2px solid var(--color-primary);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 10000000;
	opacity: .5;
	-webkit-transition: all .08s ease-out;
	transition: all .08s ease-out;
}

.cursor-outer.cursor-hover {
	opacity: 0;
}

.main-wrapper[data-magic-cursor="hide"] .mouse-cursor {
	display: none;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	z-index: -1111;
}