/*======================== 
    Common Style Css
==========================*/

/* Theme Gradient */

%axil-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

