/* -------------------------
Category Styles 
------------------------- */

.list-categories {
  margin: -15px;
  margin-bottom: -30px;

  @media #{$lg-layout} {
    margin: -10px;
  }

  @media #{$md-layout} {
    margin: -10px;
  }

  @media #{$sm-layout} {
    margin: 0;
  }

  .single-cat {
    padding: 8px;
    position: relative;
    z-index: 1;
    flex-basis: 16.666%;

    @media #{$lg-layout} {
      padding: 10px;
      flex-basis: 16.66%;
    }

    @media #{$md-layout} {
      padding: 10px;
      flex-basis: 25%;
    }

    @media #{$sm-layout} {
      padding: 10px;
      flex-basis: 33.33%;
    }

    @media #{$large-mobile} {
      padding: 5px 5px 5px 5px;
      flex-basis: 50%;
    }

    &.skeleton {
      padding: 0px 5px 5px 5px;
    }

    a {
      display: block;
    }

    .thumbnail {
      span {
        display: block !important;
      }

      img {
        @extend %radius;
        width: 100%;
        transition: all .5s;
      }

      .overlay-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(109, 16, 86, 0.00) 43.23%, rgba(109, 16, 86, 0.80) 100%), transparent -66px -87.417px / 169.474% 151.507% no-repeat;
        z-index: 1;
      }
    }

    .hot-tag {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      z-index: 3;

      .default {
        transition: all .5s;
        opacity: 1;
      }

      .hover {
        transition: all .5s;
        opacity: 0;
        background-color: rgb(146, 22, 115, 0.8);
        height: 100%;
        .content {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding-bottom: 0;
          bottom: unset;
        }
      }

      &:hover {
        .default {
          opacity: 0;
        }

        .hover {
          opacity: 1;
        }
  
        @media #{$sm-layout} {
          .default {
            opacity: 1;
          }
  
          .hover {
            opacity: 10;
          }
        }
      }
    }

    .content {
      padding-left: 8px;
      padding-right: 8px;
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 0;
      padding-bottom: 22px;
      z-index: 2;

      .title {
        margin-bottom: 0;
        color: var(--color-white);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -1px;
      }

      .explore-button {
        background-color: var(--color-white);
        color: var(--foundation-primary-color-normal);
        border: 1px solid var(--foundation-primary-color-normal);
        border-radius: var(--border-width);
        font-size: var(--font-size-b3);
        padding: 7px 15px;
        margin-top: 5px;
      }
    }

    .inner {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);

      &::before {
        position: absolute;
        content: "";
        @extend %axil-gradient;
        opacity: 0.4;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 75px;
        @extend %radius;
        transition: all .5s;
        z-index: 2;
      }

      &:hover {
        &::before {
          transform: scale(1.1);
        }

        .thumbnail {
          img {
            transform: scale(1.1);
          }
        }
      }
      &:hover {
        &::before {
          transform: scale(1.1);
        }

        .thumbnail {
          img {
            transform: scale(1.1);
          }
        }
  
        @media #{$sm-layout} {
          &::before {
            transform: scale(1);
          }
  
          .thumbnail {
            img {
              transform: scale(1);
            }
          }
        }
      }
    }


  }
}