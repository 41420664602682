/*------------------
    Author Styles  
-------------------*/
.axil-author-banner {
    .about-author {
        padding: 80px 0;
        border-bottom: 0 none;
        .thumbnail {
            a {
                img {
                    border-radius: 100%;
                    margin-right: 53px;
                    margin-bottom: 20px;
                    @media #{$sm-layout} {
                        margin-right: 20px;
                    }
                }
            }
        }
        .media-body {
            .author-info {
                .title {
                    margin-bottom: 0;
                    font-size: 30px;
                    line-height: 34px;
                }
                .subtitle {
                    color: var(--color-gray);
                    display: block;
                    margin-top: 10px;
                    display: block;
                    @media #{$sm-layout} {
                        margin-top: 8px;
                    }
                }
            }
            .content {
                .description {
                    margin-top: 20px;
                    margin-bottom: 0;
                    @media #{$sm-layout} {
                        margin-top: 6px;
                    }
                }
                .social-share-transparent {
                    margin-top: 30px;
                    @media #{$sm-layout} {
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}







