/*--------------------------
    Post Details Styles  
--------------------------*/

figure {
  margin-bottom: 30px;
  position: relative;
  margin-top: 30px;
  text-align: center;

  @media #{$sm-layout} {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .post-images {
    position: relative;
  }

  img {
    border-radius: var(--radius) var(--radius) 0 0;
  }

  figcaption {
    background: #f2f2f2;
    padding: 10px;
    text-align: left;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0 0 var(--radius) var(--radius);
    color: var(--color-heading);
    font-size: var(--font-size-b4);
    line-height: var(--line-height-b4);
  }
}



blockquote {
  margin-bottom: 60px;
  margin-top: 60px;

  @media #{$sm-layout} {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  p {
    margin-bottom: 0;
    font-size: 30px;
    line-height: 40px;
    color: var(--color-gray);
    font-weight: 500;
    font-style: normal;

    @media #{$sm-layout} {
      font-size: 24px;
      line-height: 34px;
    }
  }
}

.wp-block-image {
  &.alignwide {
    margin-left: -105px;
    margin-right: -105px;

    img {}
  }
}

.wp-block-image.alignfull,
.wp-block-cover.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;

  img {
    max-width: 100%;
    width: 100%;
  }
}


/* Blog Details Area  */

.axil-post-details {
  .post-gallery-activation {
    margin-bottom: 60px;
  }

  .embed-responsive {
    margin-bottom: 60px;

    iframe {
      width: 100%;
      height: 450px;
      border-radius: 10px;
    }
  }

  .react-audio-player {
    width: 100%;
    margin-bottom: 60px;
  }

  p {
    a {
      // color: var(--color-primary);
    }
  }

  .social-share-block {
    border-bottom: 1px solid var(--color-lighter);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .post-like {
      padding-bottom: 20px;

      a {
        display: flex;
        align-items: center;

        i {
          width: 40px;
          height: 40px;
          line-height: 37px;
          border: 1px solid var(--color-lighter);
          color: var(--color-gray);
          border-radius: 100%;
          text-align: center;
          margin-right: 20px;
          @extend %transition;
        }

        span {
          font-size: 12px;
          line-height: 16px;
          color: var(--color-gray);
        }

        &:hover {
          i {
            background-color: var(--color-primary);
            color: var(--color-white);
            border-color: var(--color-primary);
          }

          span {
            color: var(--color-primary);
          }
        }
      }
    }

    .social-icon {
      padding-bottom: 20px;
    }
  }
}

.post-details-content {
  margin-bottom: 40px;

  p {
    em {
      font-size: 12px;
      margin-top: 20px;
      display: block;
      font-style: normal;
    }
  }
}


// About Author 
.about-author {
  padding: 40px 0;
  border-bottom: 1px solid var(--color-lighter);

  .media {
    display: flex;
  }

  .thumbnail {
    a {
      display: block;
      margin-right: 53px;
      margin-bottom: 20px;

      @media #{$sm-layout} {
        margin-right: 20px;
      }

      img {
        border-radius: 100%;
      }
    }
  }

  .media-body {
    flex: 1;

    .author-info {
      .title {
        margin-bottom: 0;

        a {
          @extend %transition;

          .hover-flip-item {
            span {
              &::before {
                color: var(--color-heading);
              }

              &::after {
                color: var(--color-primary);
              }
            }
          }
        }
      }

      .subtitle {
        color: var(--color-gray);
        display: block;
        margin-top: 10px;
        display: block;

        @media #{$sm-layout} {
          margin-top: 8px;
        }
      }
    }

    .content {
      .description {
        margin-top: 20px;
        margin-bottom: 0;

        @media #{$sm-layout} {
          margin-top: 6px;
        }
      }

      .social-share-transparent {
        margin-top: 20px;

        @media #{$sm-layout} {
          margin-top: 12px;
        }
      }
    }
  }
}

// Tagcloud
.tagcloud {
  margin: -3px;
  display: flex;
  flex-wrap: wrap;

  a {
    border: 1px solid var(--color-lighter);
    font-size: 12px;
    color: var(--color-gray);
    height: 30px;
    padding: 0 10px;
    display: inline-block;
    line-height: 27px;
    border-radius: 4px;
    @extend %transition;

    &:hover {
      background-color: var(--foundation-primary-color-normal-hover);
      color: #ffffff;
      border-color: var(--foundation-primary-color-normal-hover);
    }
  }
}