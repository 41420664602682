/*-- Variables --*/
:root {
  //Themes colors
  --color-primary: #3858F6;
  --color-secondary: #D93E40;
  --color-secondary-alt: #F1352A;
  --color-tertiary: #050505;

  // Typo Color 
  --color-heading: #000000;
  --color-body: #000000;

  // Gery Colors 
  --color-gray: #65676B;
  // --color-midgray: #9F9F9F;
  --color-midgray: #878787;
  --color-light: #E4E6EA;
  --color-lighter: #CED0D4;
  --color-lightest: #F0F2F5;
  --color-border: #E6E6E6;
  --color-white: #ffffff;
  --foundation-primary-color-normal: #592c86;
  --foundation-primary-color-normal-hover: #3f1560;
  --foundation-primary-color-light: #FEE9F9;

  // Notify Colors 
  --color-success: #3EB75E;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;


  //Social icon colors
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;


  // Extra Color 
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #FBFBFD;
  --color-extra04: #1A1A1A;
  --color-extra05: #242424;

  //Border-radius
  --radius: 10px;
  --radius-big: 16px;
  --radius-small: 6px;
  --border-width: 2px;

  //Font weight
  //primary font
  --p-thin: 100;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;

  //secondary font
  --s-light: 300;
  --s-regular: 400;
  --s-medium: 500;
  --s-bold: 700;
  --s-black: 900;

  //Shadows
  --shadow-primary: 0px 4px 10px rgba(37, 47, 63, 0.1);
  --shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  --shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);


  //transition easing
  --transition: 0.4s;

  //Font Family
  --font-primary: 'Open Sans', sans-serif;
  --secondary-font: 'Open Sans', sans-serif;
  --font-awesome: 'Font Awesome 5 Pro';


  //Fonts Size
  --font-size-b1: 16px;
  --font-size-b12: 14px;
  --font-size-b2: 13px;
  --font-size-b3: 12px;
  --font-size-b4: 12px;

  //Line Height
  --line-height-b1: 1.67; //28px
  --line-height-b2: 1.5; //24px
  --line-height-b3: 1.6; //22px
  --line-height-b4: 1.3; //16px


  // Heading Font 
  --h1: 44px; //52px 1.29
  --h2: 36px; //44px 1.23
  --h3: 30px; // 34px 1.14
  --h4: 24px; //30px  1.25
  --h5: 18px; //22px 1.24
  --h6: 15px; //20px 1.25

  //figma
  --foundationprimary-colorlight: rgba(254, 233, 249, 1);
  --foundationprimary-colorlight-hover: rgba(253, 222, 246, 1);
  --foundationprimary-colorlight-active: rgba(251, 187, 235, 1);
  --foundationprimary-colornormal: rgba(243, 36, 192, 1);
  --foundationprimary-colornormal-hover: rgba(219, 32, 173, 1);
  --foundationprimary-colornormal-active: rgba(194, 29, 154, 1);
  --foundationprimary-colordark: rgba(182, 27, 144, 1);
  --foundationprimary-colordark-hover: rgba(146, 22, 115, 1);
  --foundationprimary-colordark-active: rgba(109, 16, 86, 1);
  --foundationprimary-colordarker: rgba(85, 13, 67, 1);
  --foundation-secondary-colorlight: rgba(235, 238, 254, 1);
  --foundation-secondary-colorlight-hover: rgba(225, 230, 254, 1);
  --foundation-secondary-colorlight-active: rgba(193, 203, 252, 1);
  --foundation-secondary-colornormal: rgba(55, 86, 245, 1);
  --foundation-secondary-colornormal-hover: rgba(50, 77, 221, 1);
  --foundation-secondary-colornormal-active: rgba(44, 69, 196, 1);
  --foundation-secondary-colordark: rgba(41, 65, 184, 1);
  --foundation-secondary-colordark-hover: rgba(33, 52, 147, 1);
  --foundation-secondary-colordark-active: rgba(25, 39, 110, 1);
  --foundation-secondary-colordarker: rgba(19, 30, 86, 1);
  --foundationblack-colorlight: rgba(239, 239, 239, 1);
  --foundation-black-colorlight-hover: rgba(231, 231, 231, 1);
  --foundation-black-colorlight-active: rgba(206, 206, 206, 1);
  --foundation-black-colornormal: rgba(97, 97, 97, 1);
  --foundation-black-colornormal-hover: rgba(87, 87, 87, 1);
  --foundation-black-colornormal-active: rgba(78, 78, 78, 1);
  --foundation-black-colordark: rgba(73, 73, 73, 1);
  --foundation-black-colordark-hover: rgba(58, 58, 58, 1);
  --foundation-black-colordark-active: rgba(44, 44, 44, 1);
  --foundation-black-colordarker: rgba(34, 34, 34, 1);
  --display-l58-72-regular-font-family: "OpenSans-Regular", Helvetica;
  --display-l58-72-regular-font-weight: 400;
  --display-l58-72-regular-font-size: 58px;
  --display-l58-72-regular-letter-spacing: 0px;
  --display-l58-72-regular-line-height: 72px;
  --display-l58-72-regular-font-style: normal;
  --display-l58-72-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --display-l58-72-bold-font-weight: 600;
  --display-l58-72-bold-font-size: 58px;
  --display-l58-72-bold-letter-spacing: 0px;
  --display-l58-72-bold-line-height: 72px;
  --display-l58-72-bold-font-style: normal;
  --display-m48-60-regular-font-family: "OpenSans-Regular", Helvetica;
  --display-m48-60-regular-font-weight: 400;
  --display-m48-60-regular-font-size: 48px;
  --display-m48-60-regular-letter-spacing: 0px;
  --display-m48-60-regular-line-height: 60px;
  --display-m48-60-regular-font-style: normal;
  --display-m48-60-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --display-m48-60-bold-font-weight: 600;
  --display-m48-60-bold-font-size: 48px;
  --display-m48-60-bold-letter-spacing: 0px;
  --display-m48-60-bold-line-height: 60px;
  --display-m48-60-bold-font-style: normal;
  --display-s36-45-regular-font-family: "OpenSans-Regular", Helvetica;
  --display-s36-45-regular-font-weight: 400;
  --display-s36-45-regular-font-size: 36px;
  --display-s36-45-regular-letter-spacing: 0px;
  --display-s36-45-regular-line-height: 45px;
  --display-s36-45-regular-font-style: normal;
  --display-s36-45-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --display-s36-45-bold-font-weight: 600;
  --display-s36-45-bold-font-size: 36px;
  --display-s36-45-bold-letter-spacing: 0px;
  --display-s36-45-bold-line-height: 45px;
  --display-s36-45-bold-font-style: normal;
  --headline-l32-40-regular-font-family: "OpenSans-Regular", Helvetica;
  --headline-l32-40-regular-font-weight: 400;
  --headline-l32-40-regular-font-size: 32px;
  --headline-l32-40-regular-letter-spacing: 0px;
  --headline-l32-40-regular-line-height: 40px;
  --headline-l32-40-regular-font-style: normal;
  --headline-l32-40-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --headline-l32-40-bold-font-weight: 600;
  --headline-l32-40-bold-font-size: 32px;
  --headline-l32-40-bold-letter-spacing: 0px;
  --headline-l32-40-bold-line-height: 40px;
  --headline-l32-40-bold-font-style: normal;
  --headline-m28-38-regular-font-family: "OpenSans-Regular", Helvetica;
  --headline-m28-38-regular-font-weight: 400;
  --headline-m28-38-regular-font-size: 28px;
  --headline-m28-38-regular-letter-spacing: 0px;
  --headline-m28-38-regular-line-height: 38px;
  --headline-m28-38-regular-font-style: normal;
  --headline-m28-38-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --headline-m28-38-bold-font-weight: 600;
  --headline-m28-38-bold-font-size: 28px;
  --headline-m28-38-bold-letter-spacing: 0px;
  --headline-m28-38-bold-line-height: 38px;
  --headline-m28-38-bold-font-style: normal;
  --headline-s24-31-regular-font-family: "OpenSans-Regular", Helvetica;
  --headline-s24-31-regular-font-weight: 400;
  --headline-s24-31-regular-font-size: 24px;
  --headline-s24-31-regular-letter-spacing: 0px;
  --headline-s24-31-regular-line-height: 31px;
  --headline-s24-31-regular-font-style: normal;
  --headline-s24-31-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --headline-s24-31-bold-font-weight: 600;
  --headline-s24-31-bold-font-size: 24px;
  --headline-s24-31-bold-letter-spacing: 0px;
  --headline-s24-31-bold-line-height: 31px;
  --headline-s24-31-bold-font-style: normal;
  --title-l20-26-regular-font-family: "OpenSans-Regular", Helvetica;
  --title-l20-26-regular-font-weight: 400;
  --title-l20-26-regular-font-size: 20px;
  --title-l20-26-regular-letter-spacing: 0px;
  --title-l20-26-regular-line-height: 26px;
  --title-l20-26-regular-font-style: normal;
  --title-l20-26-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --title-l20-26-bold-font-weight: 600;
  --title-l20-26-bold-font-size: 20px;
  --title-l20-26-bold-letter-spacing: 0px;
  --title-l20-26-bold-line-height: 26px;
  --title-l20-26-bold-font-style: normal;
  --title-m18-24-regular-font-family: "OpenSans-Regular", Helvetica;
  --title-m18-24-regular-font-weight: 400;
  --title-m18-24-regular-font-size: 18px;
  --title-m18-24-regular-letter-spacing: 0px;
  --title-m18-24-regular-line-height: 24px;
  --title-m18-24-regular-font-style: normal;
  --title-m18-24-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --title-m18-24-bold-font-weight: 600;
  --title-m18-24-bold-font-size: 18px;
  --title-m18-24-bold-letter-spacing: 0px;
  --title-m18-24-bold-line-height: 24px;
  --title-m18-24-bold-font-style: normal;
  --title-s16-21-regular-font-family: "OpenSans-Regular", Helvetica;
  --title-s16-21-regular-font-weight: 400;
  --title-s16-21-regular-font-size: 16px;
  --title-s16-21-regular-letter-spacing: 0px;
  --title-s16-21-regular-line-height: 21px;
  --title-s16-21-regular-font-style: normal;
  --title-s16-21-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --title-s16-21-bold-font-weight: 600;
  --title-s16-21-bold-font-size: 16px;
  --title-s16-21-bold-letter-spacing: 0px;
  --title-s16-21-bold-line-height: 21px;
  --title-s16-21-bold-font-style: normal;
  --body-l16-21-regular-font-family: "OpenSans-Regular", Helvetica;
  --body-l16-21-regular-font-weight: 400;
  --body-l16-21-regular-font-size: 16px;
  --body-l16-21-regular-letter-spacing: 0px;
  --body-l16-21-regular-line-height: 24px;
  --body-l16-21-regular-font-style: normal;
  --body-l16-21-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --body-l16-21-bold-font-weight: 600;
  --body-l16-21-bold-font-size: 16px;
  --body-l16-21-bold-letter-spacing: 0px;
  --body-l16-21-bold-line-height: 21px;
  --body-l16-21-bold-font-style: normal;
  --body-m14-19-regular-font-family: "OpenSans-Regular", Helvetica;
  --body-m14-19-regular-font-weight: 400;
  --body-m14-19-regular-font-size: 14px;
  --body-m14-19-regular-letter-spacing: 0px;
  --body-m14-19-regular-line-height: 19px;
  --body-m14-19-regular-font-style: normal;
  --body-m14-19-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --body-m14-19-bold-font-weight: 600;
  --body-m14-19-bold-font-size: 14px;
  --body-m14-19-bold-letter-spacing: 0px;
  --body-m14-19-bold-line-height: 19px;
  --body-m14-19-bold-font-style: normal;
  --body-s13-17-regular-font-family: "OpenSans-Regular", Helvetica;
  --body-s13-17-regular-font-weight: 400;
  --body-s13-17-regular-font-size: 13px;
  --body-s13-17-regular-letter-spacing: 0px;
  --body-s13-17-regular-line-height: 17px;
  --body-s13-17-regular-font-style: normal;
  --body-s13-17-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --body-s13-17-bold-font-weight: 600;
  --body-s13-17-bold-font-size: 13px;
  --body-s13-17-bold-letter-spacing: 0px;
  --body-s13-17-bold-line-height: 17px;
  --body-s13-17-bold-font-style: normal;
  --body-xs12-16-regular-font-family: "OpenSans-Regular", Helvetica;
  --body-xs12-16-regular-font-weight: 400;
  --body-xs12-16-regular-font-size: 12px;
  --body-xs12-16-regular-letter-spacing: 0px;
  --body-xs12-16-regular-line-height: 16px;
  --body-xs12-16-regular-font-style: normal;
  --body-xs12-16-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --body-xs12-16-bold-font-weight: 600;
  --body-xs12-16-bold-font-size: 12px;
  --body-xs12-16-bold-letter-spacing: 0px;
  --body-xs12-16-bold-line-height: 16px;
  --body-xs12-16-bold-font-style: normal;
  --body-xxs10-14-regular-font-family: "OpenSans-Regular", Helvetica;
  --body-xxs10-14-regular-font-weight: 400;
  --body-xxs10-14-regular-font-size: 10px;
  --body-xxs10-14-regular-letter-spacing: 0px;
  --body-xxs10-14-regular-line-height: 14px;
  --body-xxs10-14-regular-font-style: normal;
  --body-xxs10-14-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --body-xxs10-14-bold-font-weight: 600;
  --body-xxs10-14-bold-font-size: 10px;
  --body-xxs10-14-bold-letter-spacing: 0px;
  --body-xxs10-14-bold-line-height: 14px;
  --body-xxs10-14-bold-font-style: normal;
  --label-l14-19-regular-font-family: "OpenSans-Regular", Helvetica;
  --label-l14-19-regular-font-weight: 400;
  --label-l14-19-regular-font-size: 14px;
  --label-l14-19-regular-letter-spacing: 0px;
  --label-l14-19-regular-line-height: 19px;
  --label-l14-19-regular-font-style: normal;
  --label-l14-19-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --label-l14-19-bold-font-weight: 600;
  --label-l14-19-bold-font-size: 14px;
  --label-l14-19-bold-letter-spacing: 0px;
  --label-l14-19-bold-line-height: 19px;
  --label-l14-19-bold-font-style: normal;
  --label-m12-16-regular-font-family: "OpenSans-Regular", Helvetica;
  --label-m12-16-regular-font-weight: 400;
  --label-m12-16-regular-font-size: 12px;
  --label-m12-16-regular-letter-spacing: 0px;
  --label-m12-16-regular-line-height: 16px;
  --label-m12-16-regular-font-style: normal;
  --label-m12-16-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --label-m12-16-bold-font-weight: 600;
  --label-m12-16-bold-font-size: 12px;
  --label-m12-16-bold-letter-spacing: 0px;
  --label-m12-16-bold-line-height: 16px;
  --label-m12-16-bold-font-style: normal;
  --label-s11-15-regular-font-family: "OpenSans-Regular", Helvetica;
  --label-s11-15-regular-font-weight: 400;
  --label-s11-15-regular-font-size: 11px;
  --label-s11-15-regular-letter-spacing: 0px;
  --label-s11-15-regular-line-height: 15px;
  --label-s11-15-regular-font-style: normal;
  --label-s11-15-bold-font-family: "OpenSans-SemiBold", Helvetica;
  --label-s11-15-bold-font-weight: 600;
  --label-s11-15-bold-font-size: 11px;
  --label-s11-15-bold-letter-spacing: 0px;
  --label-s11-15-bold-line-height: 15px;
  --label-s11-15-bold-font-style: normal;
  --body-small-re-font-family: "OpenSans-Regular", Helvetica;
  --body-small-re-font-weight: 400;
  --body-small-re-font-size: 12px;
  --body-small-re-letter-spacing: 0px;
  --body-small-re-line-height: 14px;
  --body-small-re-font-style: normal;
  --title-small-re-font-family: "OpenSans-Regular", Helvetica;
  --title-small-re-font-weight: 400;
  --title-small-re-font-size: 16px;
  --title-small-re-letter-spacing: 0px;
  --title-small-re-line-height: 19px;
  --title-small-re-font-style: normal;
}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';