/*-------------------
 * Section Title
-----------------------*/


.section-title {
    .title {
        margin-bottom: 0;
    }
}
















