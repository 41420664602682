/*---------------------
 * Button
-----------------------*/
a {
  &.axil-link-button {
    text-decoration: none;
    @extend %foundation-primary-color-normal;
    @extend %transition;
    font-weight: var(--p-medium);
    font-size: var(--font-size-b2);
    line-height: 22px;

    &:hover {
      @extend %foundation-primary-color-normal-hover;

      @media #{$sm-layout} {
        color: var(--foundation-primary-color-normal);
      }
    }
  }
}

span {
  &.axil-cate-button {
    text-decoration: none;
    @extend %transition;
    font-weight: var(--p-medium);
    font-size: var(--font-size-b2);
    line-height: 22px;
    color: var(--color-extra04);

    &:hover {
      @extend %foundation-primary-color-normal-hover;

      @media #{$sm-layout} {
        color: var(--color-extra04);
      }
    }

    &.active {
      @extend %foundation-primary-color-normal;
    }
  }
}

a,
button,
div {
  &.axil-button {
    background: var(--color-primary);
    color: var(--color-white);
    font-weight: var(--p-medium);
    font-size: var(--font-size-b2);
    display: inline-block;
    border: 2px solid var(--color-primary);
    @extend %transition;
    padding: 0 30px;
    width: 100%;
    text-align: center;
    cursor: pointer;

    &.button-rounded {
      border-radius: 10px;
    }

    &.color-secondary-alt {
      background: var(--color-secondary-alt);
      border-color: var(--color-secondary-alt);

      &:hover {
        color: var(--color-secondary-alt);
        background: transparent;

        @media #{$sm-layout} {
          background: var(--color-secondary-alt);
        }
      }
    }

    &.color-foundation-primary-color-normal {
      padding: 11.5px;
      background: transparent;
      border: 1px solid var(--color-extra04);
      color: var(--color-extra04);

      .text-light {
        color: var(--foundation-primary-color-normal) !important;
      }

      &:hover {
        background: var(--foundation-primary-color-normal);
        border: 1px solid var(--foundation-primary-color-normal);
        color: var(--color-white);

        .text-light {
          color: var(--color-white) !important;
        }

        @media #{$sm-layout} {
          background: transparent;
          border: 1px solid var(--color-extra04);
          color: var(--color-extra04);

          .text-light {
            color: var(--foundation-primary-color-normal) !important;
          }
        }
      }
    }

    .hover-flip-item {
      span {

        &::after,
        &::before {
          color: #ffffff;
        }
      }
    }

  }
}

.link-inner {
  display: inline-block;
}

.button-highlight {
  top: 10px;
  left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 3px 15px;
  background-color: var(--foundationprimary-colornormal);
  border-radius: 8px;
  box-sizing: border-box;
  position: absolute;

  .inner {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    color: #ffffff;
    font-size: 20px;
    text-wrap: nowrap;

    @media #{$large-mobile} {
      position: static;
      padding: 0 4%;
      font-size: 14px
    }
  }
}